import React from "react";

// reactstrap components
import {
    Button,
    Col,
    Container, Modal, Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import CardGiftCard from "../components/CardGiftCard";
import {giftCardList} from "./Products/Data/GiftCardsData";
import InlineSVG from "react-inlinesvg";
import icon_phone_call from "../assets/icons/phone-call.svg";
import {massagesProductList} from "./Products/Data/MassagesData";
import CardProduct from "../components/CardProduct";
import ProductDescriptionComponent from "./Products/components/ProductDescriptionComponent";
import ProductDescriptionModalFooter from "./Products/components/ProductDescriptionModalFooter";
import {formationsProductList} from "./Products/Data/FormationsData";
import {Helmet} from "react-helmet";

const formationsHeaderBackground = require("assets/img/backgrounds/formations_background.jpg")

function FormationsPage() {
    const [modal, setModal] = React.useState(false);
    const [product, setCurrentProduct] = React.useState(undefined);

    const closeModal = () => {
        setModal(false);
        setCurrentProduct(undefined);
    };

    const toggleModal = (product) => {
        setModal(!modal);
        setCurrentProduct(product);
    };
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <Helmet>
                <title>Energy-Alix: Formations Massages Lahochi Hypnose Boulogne Billancourt</title>
                <meta name="description" content="Formations Lahochi Massage Hypnose Boulogne-Billancourt."/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={formationsHeaderBackground}/>
            <div className="section landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Formations</h1>
                        <p className="mb-5">
                            Bienvenue dans le monde apaisant et revitalisant du massage, où chaque toucher devient une
                            source de bien-être et de détente. En parcourant ces formations exceptionnelles, vous aurez
                            l'opportunité de découvrir les secrets d'un
                            art millénaire tout en bénéficiant de mon expérience passionnée dans ce domaine. <br/><br/>Avec
                            des années de pratique et de formation continue, je suis ravi de vous guider dans un voyage
                            enrichissant pour maîtriser les
                            techniques
                            du massage et élargir votre compréhension de l'anatomie, de la physiologie, et de la
                            philosophie qui sous-tendent cet art thérapeutique.
                            <br/><br/>Mon parcours a été façonné par la quête constante d'excellence, visant à apporter
                            un soulagement et un bien-être optimaux à chaque individu que je traite. Dans cette
                            perspective, je suis ravi de partager avec vous
                            mon expérience et mes compétences en matière de soins énergétiques et de massage.
                        </p>
                        <Row>
                            {formationsProductList.map((product, i) => {
                                return (
                                    <Col md="4" onClick={() => toggleModal(product)}>
                                        <CardProduct product={product}></CardProduct>
                                    </Col>)
                            })
                            }
                        </Row>
                    </div>
                </Container>
            </div>
            <MainFooter/>
            <Modal isOpen={modal} toggle={closeModal} size={"lg"}>
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h5 className="modal-title text-center" id="exampleModalLabel">
                        {product && product.title}
                    </h5>
                </div>
                <div className="modal-body">
                    <ProductDescriptionComponent product={product}></ProductDescriptionComponent>
                </div>
                <ProductDescriptionModalFooter closeModal={closeModal} ctaEmail></ProductDescriptionModalFooter>
            </Modal>
        </>
    );
}

export default FormationsPage;
