/*eslint-disable*/
import React from "react";

// reactstrap components
import InlineSVG from "react-inlinesvg";
import icon_phone_call from "../assets/icons/phone-call.svg";
import icon_gift from "../assets/icons/gift.svg";
import {Button} from "reactstrap";


function CardGiftCard({gift}) {
  return (
    <>
      <div className="card card-pricing card-gift" data-background="image" style={{backgroundImage: "url(" + gift.image + ")"}}>
        <div className="card-body">
          <h6 className="card-category">{gift.subtitle}</h6>
          <div className="card-icon">
            <InlineSVG style={{height: "50px"}} src={icon_gift}></InlineSVG>
          </div>
          <h3 className="card-title">{gift.price}</h3>
          <div className="card-footer mt-3">
            {gift.description}
          </div>
          {gift.extra && (
            <div className="card-description">
              {gift.extra}
            </div>
          )}
        </div>
      </div>

    </>
  );
}

export default CardGiftCard;
