import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import MainFooter from "../components/Footers/MainFooter.js";
import Map from "../components/Map/Map";
import {Link} from "react-router-dom";
import TestimonialsSection from "./Sections/TestimonialsSection";
import {GeneralTestimonials} from "./Products/Data/TestimonialsData";
import {Helmet} from "react-helmet";
import OpeningHoursSectionFooter from "./Products/components/OpeningHoursSectionFooter";
import icon_tiktok from "../assets/icons/tiktok.svg";
import InlineSVG from "react-inlinesvg";

function LandingPage() {

  const location = {
    addressLine1: '31 rue de Fessart',
    addressLine2: '92100 Boulogne-Billancourt',
    lat: 48.84429666459736,
    lng: 2.239504026300989
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <Helmet>
        <title>Energy Alix - Massages, Réflexologie et Hypnose à Boulogne Billancourt 2</title>
        <meta name="description" content="Votre bulle de Bien-être à Boulogne-Billancourt. Massages, soins holitsiques et hypnose. A domicile et au cabinet: 31 rue de Fessart, 92100 Boulogne-Billancourt"/>
      </Helmet>
      <DefaultNavbar showWhiteBranding={false}/>
      <LandingPageHeader/>
      <div className="main">
        <div className="text-center no-padding-bottom">
          <Container>
            <Row>
              <Col md="4">
                <Link to="/massages">
                  <Card className="card-service ml-auto mr-auto">
                    <div className="register-title no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/massage.jpg") + ")",
                    }}></div>
                    <div className="register-content no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/massage.jpg") + ")",
                    }}></div>
                    <div className="card-blurry-title">
                      <h2 className="info-title-main-page h3">Massages et Réflexologie</h2>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col md="4">
                <Link to="/holistic">
                  <Card className="card-service ml-auto mr-auto">
                    <div className="register-title no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/hollistique.jpg") + ")",
                    }}></div>
                    <div className="register-content no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/hollistique.jpg") + ")",
                    }}></div>
                    <div className="card-blurry-title">
                      <h2 className="info-title-main-page h3">Soins Holistiques</h2>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col md="4">
                <Link to="/hypnose">
                  <Card className="card-service ml-auto mr-auto">
                    <div className="register-title no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/hypnose.jpg") + ")",
                    }}></div>
                    <div className="register-content no-transform" style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + require("../assets/img/products/hypnose.jpg") + ")",
                    }}></div>
                    <div className="card-blurry-title">
                      <h2 className="info-title-main-page h3">Hypnose</h2>
                    </div>
                  </Card>
                </Link>
              </Col>
            </Row>
            <div className="services-section-description">
              <h2 className="title">Déstressez, Relaxez, régénérez...</h2>

              <p className="card-description text-center">
                Faites votre choix parmi une vaste selection de prestations. Laissez vous tenter par un moment de relaxation extrême... Et trouvez le bien-être en faisant l'expérience d'une prestation haut de gamme.
              </p>
            </div>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">A propos de moi</h2>
            <Row>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <Link to="/about-me">
                      <img
                        alt="Alix Imhaus"
                        src={require("../assets/img/profile/profile.jpg")}
                      />
                    </Link>
                  </div>
                  <CardBody>
                    <Link to="/about-me">
                      <div className="author">
                        <CardTitle tag="h4">Alix Imhaus</CardTitle>
                        <h6 className="card-category">Praticienne bien-être</h6>
                      </div>
                    </Link>
                    <p className="card-description text-center">
                      Je suis Alix et je vous propose des soins énergétiques ou Lahochi, des massages métamorphiques ou
                      de bien-être et de la réflexologie plantaire afin de vous aider en rééquilibrant les flux
                      énergétiques notamment.

                      Après la médecine traditionnelle que j’ai côtoyée comme secouriste pendant des années, je me suis
                      tournée vers des médecines alternatives : la médecine chinoise et les soins énergétiques tout en
                      débutant une formation de magnétiseur. Ces différentes pratiques me sont apparues comme
                      complémentaires : elles m’ont permis d’écouter et de soulager la douleur de l’autre dans
                      l’urgence, mais aussi d’apprendre à comprendre, à lâcher prise, à ressentir et à transmettre le
                      fluide aux autres…
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter"/>
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.tiktok.com/@energy.alix"
                    >
                      <InlineSVG className="mt-n1" style={{width: "1em", height: "1em"}} src={icon_tiktok}></InlineSVG>
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/alix-imhaus-474a3b225"
                    >
                      <i className="fa fa-linkedin"/>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <TestimonialsSection testimonials={GeneralTestimonials}></TestimonialsSection>
      </div>
      <div className="section-dark no-padding-bottom">
        <Map location={location} zoomLevel={14}/>
      </div>
      <div className={"section landing-section section-dark"}>
        <Container>
          <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
        </Container>
      </div>
      <MainFooter/>
    </>
  );
}

export default LandingPage;
