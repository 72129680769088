/*eslint-disable*/
import React from "react";

// reactstrap components
import {Card} from "reactstrap";

function CardProduct({product}) {
  return (
    <Card className="card-product">
      <div className="card-image">
        <img alt={product.title} className="img img-raised" src={product.image}/>
      </div>
      <div className="card-body">
        <h4 className="card-title mb-1">{product.title}</h4>
        <h6 className="card-category">{product.subtitle}</h6>
        <p className="card-description">{product.description}</p>
        <hr/>
        <div className="card-footer">
          <div>
            <div className="author">Séance</div>
            <div className="stats"><i className="fa fa-clock-o mr-1"></i>{product.duration}</div>
          </div>
          {product.date && (<div><div className="author">Date</div><div className="stats"><i className="fa fa-clock-o mr-1"></i>{product.date}</div></div>)}
        </div>
      </div>
    </Card>
  );
}

export default CardProduct;
