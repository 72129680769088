export const holisticProductList = [
  {
    id: "energetic",
    tags: ["holistic"],
    title: "Soin énergétique",
    subtitle: "Harmonie",
    image: require("assets/img/products/holistic/energetic_treatment.jpg"),
    description: "Harmonisez vos énergies, libérez vos blocages",
    duration: "60 min",
    detailedDescription: {
      sessionContent:
        <>
          <p>Tout d’abord, je vous pose une série de questions pour aborder la séance en pleine conscience.</p>
          <p>Vous êtes confortablement installés avec coussin, plaid. L’apposition des mains sur le corps et utilisation de matériel supplèmentaire peut être utilisé selon votre besoin (pendule, baton..) </p>
          <p>Son objetif est d’éléver et d’équilibrer l’énergie vitale pour <b>faciliter le bien-être</b> sur le plan <b>physique, mentale, affective</b> et <b>spirituelle</b></p>
        </>,
      bienfaits:
        <>
          <p>Les soins énergétiques peuvent avoir de multiples bienfaits insoupçonnés. Ce n’est pas pour rien que cette médecine douce est utilisée depuis la nuit des temps à travers le monde…</p>
          <p>Mon travail consiste à libérer vos énergies. Ainsi, tous les blocages se lèvent, aussi bien au niveau du corps, des émotions et de la vibration. Cela permet non seulement d’améliorer mais aussi de prévenir certains
            troubles.</p>
          <p>Un rendez-vous permet également de revitaliser en profondeur votre organisme. Vous vous sentez alors plus détendu, plus serein, mais aussi plus motivé et plus dynamique. La séance est donc également un accompagnement
            bien-être.</p>
          <p>Le nettoyage énergétique est aussi un bon moyen d’agir sur une dimension spirituelle. Au travers d’un soin, il est possible de libérer certaines mémoires ancestrales. Cela peut sembler étonnant, mais les histoires de nos
            ancêtres impactent profondément notre vie actuelle.</p>
          <p>Ces mémoires transgénérationnelles imposent parfois de revivre indéfiniment certaines situations problématiques. En laissant les nœuds énergétiques se dénouer, tout s’apaise naturellement, et il devient possible d’avancer enfin
            vers de nouveaux horizons…</p>
        </>,
    }
  },
  {
    id: "lahochi",
    tags: ["holistic"],
    title: "Lahochi",
    subtitle: "Energie et spiritualité",
    image: require("assets/img/products/holistic/lahochi.jpg"),
    description: "Technique énergétique très puissante qui se transmet par apposition des mains mais également un outil de développement personnel et spirituel.",
    duration: "45 à 60 min",
    detailedDescription: {
      sessionContent:
        <>
          <p>Tout d’abord, je vous pose une série de questions pour aborder la séance en pleine conscience.</p>
          <p>Vous êtes confortablement installés avec coussin, plaid. L’apposition des mains sur la tête, l’épaule, la clavicule, la hanche, le coeur. </p>
          <p>Son objetif est d’éléver et d’équilibrer l’énergie vitale pour faciliter le bien-être sur sur le plan physique, mentale, affective et spirituelle.</p>
        </>,
      bienfaits:
        <>
          <p><b>Quelques vertues</b> fournies par Elizabeth CHANDLER :</p>
          <ul>
            <li><b>Ouvre les 21 centres d’énergie du corps</b> ou le système d’anatomie des méridiens.</li>
            <li>retisse le champs d’énergie en réparant les brèches, les déchirures et les régions endommagées par une variété d’influence incluant une naissance difficile, une opération chirurgicale, la radiation, la drogue, les accidents,
              les blessures anciennes et présentes de la vie, tous les types de traumatismes, les intoxications environnementales, les programmations négatives et les tendances génétiques.
            </li>
            <li>introduit des vibrations de couleur et de santé</li>
            <li>améliore, dans le champ éthérique du corps, un traitement tel que l’acupuncture par exemple</li>
            <li>charge de vibrations les essences florales et minérales</li>
            <li>équilibre la circulation du fluide crânien dans le cerveau et équilibre le système cérébro-spinal</li>
            <li>ouvre les blocages dans les corps d’énergies subtiles</li>
            <li>ouvre et nettoie le système des chakras</li>
            <li>réaligne la structure du corps tel un système de grille multidimensionnelle qui maintient le corps dans son intégrité</li>
            <li>dénoue les blocages électriques dans l’énergie du corps, semblable à un réseau de liens entre la mémoire cellulaire et le corps physique, afin que l’information puisse passer librement</li>
            <li>élargit le champ d’énergie</li>
            <li>place la mémoire divine dans la couche externe du champ énergétique qui transmettra la mémoire divine au corps entier</li>
            <li>place la mémoire divine dans le champ éthérique dans le but de guérir les déséquilibres</li>
            <li>active le corps de lumière</li>
            <li>accélère la guérison et les dons spirituels</li>
          </ul>

          <p>Il calme le système nerveux, il favorise la relaxation, l’état de bien-être.</p>
          <p>Il soulage les tensions physiques et mentales.</p>
          <p>Il atténue voire supprime, les noeuds, zones de tension et de douleurs liées aux tensions concernées.</p>
        </>,
      counterIndications:
        <>
          <ul>
            <li>Fièvre</li>
            <li>Maladies infectieuses</li>
            <li>Infection cutanée contagieuses (psoriasis, mycoses, verrues…)</li>
            <li>Fractures et blessures récentes (Possibilité de ne pas masser sur cette zone)</li>
            <li>Troubles circulatoires (phlébite, varice, trombose, hématone…)</li>
            <li>Grossesse</li>
            <li>Pathologies : cancer, épilepsie, diabète... uniquement sur l’accord du médecin traitant</li>
          </ul>

          <i>En cas de doute : <br/>
            Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
        </>,
    }
  },
  {
    id: "metamorphic",
    tags: ["holistic"],
    title: "Massage métamorphique",
    subtitle: "Eveillez votre force de vie...",
    image: require("assets/img/products/holistic/metamorphic.jpg"),
    description: "Technique inspirée de la réflexologie et qui se distingue par son toucher particulier et son”laisser être”.",
    duration: "70 min",
    detailedDescription: {
      sessionContent:
        <>
          <p>Vous êtes allongé confortablement habillé (coussin, plaid et couverture chauffante sont à votre disposition).</p>
          <p>La séance débute avec un toucher délicat sur les pieds, puis les mains et un toucher sur la tête.</p>
          <p>Ce massage permet un voyage de détente pour se reconnecter à soi-même.</p>
          <br/>
          <h6>Historique:</h6>
          <p>
            Cette relaxation holistique, découverte en 1950 par Robert St John, naturopathe-réflexologue, a pour principe de reconnceter l’individu à sa période prénatale. La vie commence dès la conception. Nous trouvons le schéma prénatal
            sur les points réflexes de la colonne vertébrale est le centre et représente la structure du schéma prénatal.
          </p>
          <br/>
          <p>
            La métamorphose est avant tout un soin qui a pour but de <b>libérer les transmissions héréditaires, les problématiques répétitives et d’actualiser dans le présent la Force de Vie essentielle à la création.</b> L’éveil de cette
            Force de vie qui s’est comme endormie, afin de lui permettre de <b>nettoyer</b> ce qui fait <b>obstacle, ici et maintenant</b>, à ce que nous sommes au-delà de <b>l’héritage reçu par la lignée généalogique.</b>
          </p>
          <br/>
          <br/>
          <h6>Pourquoi les pieds, les mains et la tête?</h6>
          <p>
            Ces trois parties du corps sont trois surfaces de communication extérieure, correspondant à trois actions fondamentales:
            <ul>
              <li>Les pieds sont en rapport à notre capacité d’action d’avancement : « avancer dans la vie »</li>
              <li>Les mains à la capacité d’action : « oser prendre la vie en pleines mains »</li>
              <li>La tête à la capacité de penser, la réflexion : « être maître de sa vie »</li>
            </ul>
          </p>

          <p>
            Cette technique agit sur :
            <ul>
              <li>Mémoires de la période de gestation liées aux problèmes de conception, déni de grossesse, ou difficultés pendant le grossesse.</li>
              <li>Difficultés à la naissance, traumatisme, forceps, césarienne.</li>
              <li>Enfants atteints d’hyperactivité, d’hypersensibilité, troubles du sommeil, du langage ou comportemental.</li>
              <li>L’insomnie, difficultés du sommeil, cauchemars répétés.</li>
              <li>Les blocages ou manque d’énergie.</li>
              <li>Les difficultés de mémorisation.</li>
              <li>Le stress</li>
              <li>L’anxiété, les angoisses, la nervosité intérieure.</li>
              <li>Utile à chacun dans son développement personnel, elle permet aussi de négocier au mieux les périodes de grand changement : deuil, séparation, changement de profession ou de lieu de vie, mise en oeuvre de projets importants etc</li>
            </ul>
          </p>
          <br/>
          <p>
            La séance dure environ 1h15 (30 minutes pour les bébés ou les enfants).
          </p>
          <br/>
          <p>
            Le praticien se positionne comme catalyseur à la disposition du potentiel d’auto-guérison de la personne. Il est là pour vous accompagner dans votre processus d’évolution, sans jugement dans un pur respect de l’être.
          </p>
          <br/>
          <p>
            Le sujet traité peut ressentir des sensations physiques telles que la chaleur, la fraîcheur, les fourmillements, mais plus généralement une grande détente et une relaxation profonde pouvant l’amener jusqu’à l’endormissement.
          </p>
          <br/>
          <p>
            C’est entre les séances, quand la personne est de nouveau confrontée à son quotidien que s’opèrent progressivement des changements : apaisement des émotions, facilités de centrage et de mémorisation, retour à la vitalité avec évacuation des problèmes psychosomatiques…
          </p>
          <br/>
          <p>
            Le praticien ne peut jamais prédire ce qui va se passer chez la personne, c’est la force vitale du receveur, guidée par son intelligence innée qui aidera à révéler ce qui est juste pour elle.
          </p>
          <br/>
          <p>
            De même le praticien ne peut prévoir un nombre de séances.
          </p>
          <br/>
          <p>
            Vous êtes libre selon vos préférences du rythme des séances (hebdomadaire, mensuel, ponctuel).
          </p>
        </>,
      counterIndications:
        <>
          <b>Aucune contre-indication médicale</b> sont les bienvenus: adultes, femmes enceintes, enfants.
        </>
    }
  }
]
