import React from "react";
import {Link, useLocation} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import logo_dark from "../../assets/img/logo_dark.svg";
// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from "reactstrap";
import InlineSVG from "react-inlinesvg";
import icon_gift from "../../assets/icons/gift.svg";
import icon_tiktok from "../../assets/icons/tiktok.svg";

function DefaultNavbar({showWhiteBranding = true}) {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [brandingColor, setBrandingColor] = React.useState(showWhiteBranding ? "bg-white-branding" : "");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const [menuDefaultOpened, setMenuDefaultOpened] = React.useState(false);
    const location = useLocation();

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");
                setBrandingColor("")
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("navbar-transparent");
                setBrandingColor(showWhiteBranding ? "bg-white-branding" : "")
            }
        };

        const updateMenuDefaultOpened = (event) => {
            if (window.innerWidth < 992) {
                setMenuDefaultOpened(true)
            } else {
                setMenuDefaultOpened(false)
            }
        }
        updateMenuDefaultOpened()

        window.addEventListener("scroll", updateNavbarColor);
        window.addEventListener("resize", updateMenuDefaultOpened);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
            window.removeEventListener("resize", updateMenuDefaultOpened);
        };
    });
    return (
        <Navbar
            className={classnames("fixed-top", navbarColor, brandingColor)}
            color-on-scroll="300"
            expand="lg"
        >
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        to="/"
                        title="energy-alix"
                        tag={Link}
                    >
                        <InlineSVG style={{height: "3em"}} src={logo_dark}></InlineSVG>
                        Energy Alix
                    </NavbarBrand>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: navbarCollapse,
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1"/>
                        <span className="navbar-toggler-bar bar2"/>
                        <span className="navbar-toggler-bar bar3"/>
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        {menuDefaultOpened && (
                            <UncontrolledDropdown nav inNavbar isOpen>
                                <DropdownToggle
                                    aria-expanded={false}
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="dropdownMenuButton"
                                    nav
                                    role="button"
                                >
                                    Menu
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-info">
                                    <DropdownItem header tag="h6">Prestations</DropdownItem>
                                    <Link to="/massages"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/massages" ? "h2 font-weight-bold selected" : "h2"}>Massages
                                        & Réflexologie</DropdownItem></Link>
                                    <Link to="/holistic"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/holistic" ? "h2 font-weight-bold selected" : "h2"}>Soins
                                        Holistiques</DropdownItem></Link>
                                    <Link to="/hypnose"><DropdownItem tag="h1"
                                                                      className={location.pathname === "/hypnose" ? "h2 font-weight-bold selected" : "h2"}>Hypnose</DropdownItem></Link>
                                    <DropdownItem divider/>
                                    <Link to="/packages"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/packages" ? "h2 font-weight-bold selected" : "h2"}>Les
                                        formules</DropdownItem></Link>
                                    <Link to="/future-mom"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/future-mom" ? "h2 font-weight-bold selected" : "h2"}>Spécial
                                        future maman</DropdownItem></Link>
                                    <Link to="/companies"><DropdownItem tag="h1"
                                                                        className={location.pathname === "/companies" ? "h2 font-weight-bold selected" : "h2"}>Entreprises</DropdownItem></Link>
                                    <Link to="/pricing"><DropdownItem tag="h1"
                                                                      className={location.pathname === "/pricing" ? "h2 font-weight-bold selected" : "h2"}>Tarifs</DropdownItem></Link>
                                    <DropdownItem divider/>
                                    <Link to="/about-me"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/about-me" ? "h2 font-weight-bold selected" : "h2"}>A
                                        propos de moi</DropdownItem></Link>
                                    <Link to="/formations"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/formations" ? "h2 font-weight-bold selected" : "h2"}><i
                                        className="nc-icon nc-hat-3 m-0 mr-2"/>Formations</DropdownItem></Link>
                                    <Link to="/gift-cards"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/gift-cards" ? "h2 font-weight-bold selected" : "h2"}><InlineSVG
                                        style={{height: "1.1em"}} className={"m-0 mr-2"}
                                        src={icon_gift}></InlineSVG> Cartes cadeaux</DropdownItem></Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )
                        }
                        {!menuDefaultOpened && (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                    aria-expanded={false}
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="dropdownMenuButton"
                                    nav
                                    role="button"
                                >
                                    Menu
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-info">
                                    <DropdownItem header tag="h6">Prestations</DropdownItem>
                                    <Link to="/massages"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/massages" ? "h2 font-weight-bold selected" : "h2"}>Massages
                                        & Réflexologie</DropdownItem></Link>
                                    <Link to="/holistic"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/holistic" ? "h2 font-weight-bold selected" : "h2"}>Soins
                                        Holistiques</DropdownItem></Link>
                                    <Link to="/hypnose"><DropdownItem tag="h1"
                                                                      className={location.pathname === "/hypnose" ? "h2 font-weight-bold selected" : "h2"}>Hypnose</DropdownItem></Link>
                                    <DropdownItem divider/>
                                    <Link to="/packages"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/packages" ? "h2 font-weight-bold selected" : "h2"}>Les
                                        formules</DropdownItem></Link>
                                    <Link to="/future-mom"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/future-mom" ? "h2 font-weight-bold selected" : "h2"}>Spécial
                                        future maman</DropdownItem></Link>
                                    <Link to="/companies"><DropdownItem tag="h1"
                                                                        className={location.pathname === "/companies" ? "h2 font-weight-bold selected" : "h2"}>Entreprises</DropdownItem></Link>
                                    <Link to="/pricing"><DropdownItem tag="h1"
                                                                      className={location.pathname === "/pricing" ? "h2 font-weight-bold selected" : "h2"}>Tarifs</DropdownItem></Link>
                                    <DropdownItem divider/>
                                    <Link to="/about-me"><DropdownItem tag="h1"
                                                                       className={location.pathname === "/about-me" ? "h2 font-weight-bold selected" : "h2"}>A
                                        propos de moi</DropdownItem></Link>
                                    <Link to="/formations"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/formations" ? "h2 font-weight-bold selected" : "h2"}><i
                                        className="nc-icon nc-hat-3 m-0 mr-2"/>Formations</DropdownItem></Link>
                                    <Link to="/gift-cards"><DropdownItem tag="h1"
                                                                         className={location.pathname === "/gift-cards" ? "h2 font-weight-bold selected" : "h2"}><InlineSVG
                                        style={{height: "1.1em"}} className={"m-0 mr-2"}
                                        src={icon_gift}></InlineSVG> Cartes cadeaux</DropdownItem></Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.facebook.com/profile.php?id=100069660918708"
                                target="_blank"
                                title="Like us on Facebook"
                            >
                                <i className="fa fa-facebook-square"/>
                                <p className="d-lg-none">Facebook</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.instagram.com/energyalix/"
                                target="_blank"
                                title="Follow us on Instagram"
                            >
                                <i className="fa fa-instagram"/>
                                <p className="d-lg-none">Instagram</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <Button
                                className="btn-round mx-3"
                                color="primary"
                                href="https://www.resalib.fr/praticien/45046-alix-imhaus-praticien-en-soins-energetiques-boulogne-billancourt#newrdvmodal"
                                target="_blank"
                            >
                                <i className="nc-icon nc-book-bookmark"></i> Prendre rendez-vous
                            </Button>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default DefaultNavbar;
