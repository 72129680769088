import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Col,
  Container, Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import {Helmet} from "react-helmet";
import {massagesProductList} from "./Products/Data/MassagesData";
import {hypnoseProductList} from "./Products/Data/HypnoseData";
import {reflexologyProductList} from "./Products/Data/ReflexologyData";
import InlineSVG from "react-inlinesvg";
import icon_phone_call from "../assets/icons/phone-call.svg";

const formationsHeaderBackground = require("assets/img/backgrounds/companies.jpg")

function CompanyOfferPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <Helmet>
        <title>Energy-Alix: Massages, Soins énergétiques en entreprise</title>
        <meta name="description" content="Massages Relaxant et métamorphiques, soins énergétiques, hypnose en entreprise."/>
      </Helmet>
      <DefaultNavbar/>
      <DefaultPageHeader backgroundImage={formationsHeaderBackground}/>
      <div className="section landing-section">
        <Container>
          <div className="owner">
            <h1 className="title mb-5 h2">Offres entreprises</h1>
            <p className="mb-5">
              Découvrez une nouvelle dimension de bien-être au sein de votre entreprise avec un service de massages et de soins énergétiques personnalisés. Je suis fière de vous proposer une expérience unique qui transforme votre
              lieu de travail en un oasis de détente et de revitalisation. Mes qualifications me permettent d'apporter une touche de sérénité à votre environnement professionnel, en aidant vos employés à éliminer le stress, à soulager les
              tensions musculaires et à rétablir l'harmonie intérieure.
            </p>
            <p>
              Grâce à mes services, vous investissez dans la santé et le bonheur de votre équipe, tout en favorisant la productivité et la satisfaction au travail. Offrez à vos collaborateurs un moment de bien-être et contribuez à créer un
              lieu de travail exceptionnel.
            </p>
          </div>
        </Container>
      </div>
      <div className="section section-light">
        <Container>
          <h3>Choisissez votre prestation entreprise</h3>
          <hr/>
          <Row>
            {[
              ...massagesProductList.filter((product) => product.id === "massage_assis"),
              ...hypnoseProductList.filter((product) => product.id === "hypnose_corporelle"),
              ...reflexologyProductList.filter((product) => product.id === "reflexology_plantar" || product.id === "reflexology_facial"),
            ].map((product, i) => {
              return (
                <Col lg="3">
                  <Card className="card-product odd card-plain card-product-enterprise">
                    <div className="card-image">
                      <img alt={product.title} className="img img-raised" src={product.image}/>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title mb-1">{product.title}</h4>
                      <h6 className="card-category">{product.subtitle}</h6>
                      <p className="card-description">{product.descriptionEnterprise}</p>
                      <hr/>
                      <div className="card-footer">
                        <div>
                          <div className="author">Séance</div>
                          <div className="stats"><i className="fa fa-clock-o mr-1"></i>{product.durationEnterprise}</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>)
            })
            }
          </Row>
          <Row>
            <p className="text-center">
              En fonction du temps pour vos salariés et du lieu de travail soit massage sur chaise ou sur table.
              Cette activité nécessite un local, ou salle pour un meilleur apaisement pour le salarié.
            </p>
          </Row>
        </Container>
      </div>
      <div className="section text-center">
        <Container>
          <Row>
            <p className="mb-5">
              Prenez contact pour toute demande de renseignements ou pour l'organisation d'une prestation au sein de votre entreprise.
            </p>
          </Row>
          <Row>
            <Col lg="6">
              <Row className="btn-round text-center">
                <Col md="6" className="py-1">
                  <Button
                    className="btn-round"
                    color="success"
                    href="mailto:energy.alix@gmail.com"
                    target="_blank"
                  >
                    <i className="nc-icon nc-email-85 pr-3"/>
                    energy.alix@gmail.com
                  </Button>
                </Col>
                <Col md="6" className="py-1">
                  <Button
                    className="btn-round phone-button"
                    color="success"
                    outline
                    href="tel:+33766304547"
                    target="_blank"
                  >
                    <InlineSVG className="mx-2" style={{width: "1.1em", height: "1.1em"}} src={icon_phone_call}></InlineSVG>
                    07 66 30 45 47
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <MainFooter/>
    </>
  )
    ;
}

export default CompanyOfferPage;
