import {useEffect, useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col, Modal,
} from "reactstrap";

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar.js";
import MainFooter from "../../components/Footers/MainFooter.js";
import CardProduct from "../../components/CardProduct";
import ProductDescriptionComponent from "./components/ProductDescriptionComponent";
import ProductDescriptionModalFooter from "./components/ProductDescriptionModalFooter";
import DefaultPageHeader from "../../components/Headers/DefaultPageHeader";
import TestimonialsSection from "../Sections/TestimonialsSection";
import {HolisticTestimonials} from "./Data/TestimonialsData";
import OpeningHoursSectionFooter from "./components/OpeningHoursSectionFooter";
import {Helmet} from "react-helmet";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import {allProductsList} from "./Data/AllProductsData";
import useQuery from "../../utils/UseQuery";

function HolisticPage() {
    const [modal, setModal] = useState(false);
    const [product, setCurrentProduct] = useState(undefined);
    const navigate = useNavigate();

    const closeModal = () => {
        setModal(false);
        setCurrentProduct(undefined)
    };

    const onModalClosed = () => {
        navigate({
            search: createSearchParams({}).toString()
        });
    };

    let query = useQuery();
    let holisticProducts = allProductsList.filter(it => it.tags?.indexOf("holistic") > -1);
    let holisticAnimalsProducts = allProductsList.filter(it => it.tags?.indexOf("holistic_animals") > -1);

    useEffect(() => {
        let productId = query.get("pid");
        if (productId) {
            setModal(true);
            setCurrentProduct(allProductsList.find(it => it.id === productId))
        }
    }, [query]);
    document.documentElement.classList.remove("nav-open");
    useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    const seoTag = (product) => {
        return (product && (product.titleText || product.title)) || "Soins Holistiques"
    };

    return (
        <>
            <Helmet>
                <title>{seoTag(product) + " - Energy Alix - Boulogne Billancourt"}</title>
                <meta name="description" content={seoTag(product) + " Boulogne-Billancourt - Boulogne (31 rue de Fessart, 92100). A domicile et au Cabinet."}/>
                <link rel="canonical" href="https://energy-alix.fr/holistic"/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={require("assets/img/backgrounds/massage_back_4.jpg")}/>
            <div className="section landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Soins Holistiques</h1>
                        <Row>
                            {holisticProducts.map((product, i) => {
                                return (
                                    <Col md="4">
                                        <Link to={"/holistic?pid=" + product.id}>
                                            <CardProduct product={product}></CardProduct>
                                        </Link>
                                    </Col>)
                            })
                            }
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="section section-dark landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Animaux</h1>
                        <Row>
                            {holisticAnimalsProducts.map((product, i) => {
                                return (
                                    <Col md="4">
                                        <Link to={"/holistic?pid=" + product.id}>
                                            <CardProduct product={product}></CardProduct>
                                        </Link>
                                    </Col>)
                            })
                            }
                        </Row>
                    </div>
                </Container>
            </div>
            <TestimonialsSection testimonials={HolisticTestimonials}></TestimonialsSection>
            <div className={"section landing-section section-dark"}>
                <Container>
                    <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
                </Container>
            </div>
            <MainFooter/>
            <Modal isOpen={modal} onClosed={onModalClosed} toggle={closeModal} size={"lg"}>
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h5 className="modal-title text-center" id="exampleModalLabel">
                        {product && product.title}
                    </h5>
                </div>
                <div className="modal-body">
                    <ProductDescriptionComponent product={product}></ProductDescriptionComponent>
                </div>
                <OpeningHoursSectionFooter/>
                <ProductDescriptionModalFooter closeModal={closeModal}></ProductDescriptionModalFooter>
            </Modal>
        </>
    );
}

export default HolisticPage;
