import {Button, Col, Row} from "reactstrap";
import React from "react";
import InlineSVG from "react-inlinesvg";

import icon_phone_call from "assets/icons/phone-call.svg";

function ProductDescriptionModalFooter({closeModal, ctaEmail}) {
  return (
    <div className="modal-footer text-center">
      <Row>
        <Col lg="6">
          <Row className="btn-round text-center">
            <Col md="12" className="py-1">
              {ctaEmail && (<Button
                className="btn-round"
                color="success"
                href="mailto:energy.alix@gmail.com"
                target="_blank"
              >
                <i className="nc-icon nc-email-85 pr-3"/>
                energy.alix@gmail.com
              </Button>)}
              {!ctaEmail && ( <Button
                className="btn-round"
                color="primary"
                href="https://www.resalib.fr/praticien/45046-alix-imhaus-praticien-en-soins-energetiques-boulogne-billancourt#newrdvmodal"
                target="_blank"
              >
                <i className="mx-2 nc-icon nc-book-bookmark"></i>Prendre Rendez-vous en Ligne
              </Button>)}
            </Col>
            <Col md="12" className="py-1">
              <Button
                className="btn-round phone-button"
                color="success"
                outline
                href="tel:+33766304547"
                target="_blank"
              >
                <InlineSVG className="mx-2" style={{width: "1.1em", height: "1.1em"}} src={icon_phone_call}></InlineSVG>
                07 66 30 45 47
              </Button>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Button className="btn-link" color="success" type="button" onClick={() => closeModal()}>
            Fermer
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ProductDescriptionModalFooter;
