import React from "react";

// reactstrap components
import {
    Col,
    Container,
    Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import {Helmet} from "react-helmet";
import CardProduct from "../components/CardProduct";

const certificationsImages = [
    {name: "magnetiseur", picture: require("../assets/img/certifications/cert_1.png")},
    {name: "Lahochi", picture: require("../assets/img/certifications/cert_2.png")},
    {name: "Massage Metamorphique", picture: require("../assets/img/certifications/cert_3.png")},
    {name: "Massage Bien-Etre", picture: require("../assets/img/certifications/cert_4.png")},
    {name: "Reflexologie plantaire", picture: require("../assets/img/certifications/cert_5.png")},
    {name: "Massage Bodhypnosis", picture: require("../assets/img/certifications/cert_6.png")},
    {name: "Reflexologie Faciale", picture: require("../assets/img/certifications/cert_7.png")},
    {name: "Massage Suédois", picture: require("../assets/img/certifications/cert_8.png")},
    {name: "Massage et Relaxation prénatal", picture: require("../assets/img/certifications/cert_9.png")},
    {name: "Massage aux bols tibétains", picture: require("../assets/img/certifications/cert_10.png")},
    {
        name: "Hypnose regressive et spirituelle energetique",
        picture: require("../assets/img/certifications/cert_11.png")
    },
    {name: "Massage Ayurvedique", picture: require("../assets/img/certifications/cert_12.png")},
    {name: "Massage Bodhypnosis niveau 2", picture: require("../assets/img/certifications/cert_13.png")},
]

const aboutMePageHeaderBack = require("assets/img/backgrounds/aboutme.jpg")

function AboutMePage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <Helmet>
                <title>À Propos - Energy Alix - Massages, Réflexologie, Hypnose à Boulogne Billancourt</title>
                <meta name="description"
                      content="Votre bulle de Bien-être à Boulogne-Billancourt. Massages, soins holitsiques et hypnose. A domicile et au cabinet: 31 rue de Fessart, 92100 Boulogne-Billancourt"/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={aboutMePageHeaderBack}/>
            <section className="section profile-content">
                <Container className="text-center">
                    <div className="owner">
                        <div className="avatar">
                            <img
                                alt={"Energy Alix - ALix Imhaus"}
                                className="img-circle img-no-padding img-responsive"
                                src={require("assets/img/profile/profile.jpg")}
                            />
                        </div>
                        <div className="name">
                            <h1 className="title h4">
                                Alix Imhaus <br/>
                            </h1>
                            <h6 className="description">Praticienne bien-être</h6>
                        </div>
                    </div>
                    <p className="mt-5">
                        Je suis Alix et je vous propose des soins énergétiques ou Lahochi, des massages métamorphiques
                        ou
                        de bien-être et de la réflexologie plantaire afin de vous aider en rééquilibrant les flux
                        énergétiques notamment.

                        Après la médecine traditionnelle que j’ai côtoyée comme secouriste pendant des années, je me
                        suis
                        tournée vers des médecines alternatives : la médecine chinoise et les soins énergétiques tout en
                        débutant une formation de magnétiseur. Ces différentes pratiques me sont apparues comme
                        complémentaires : elles m’ont permis d’écouter et de soulager la douleur de l’autre dans
                        l’urgence, mais aussi d’apprendre à comprendre, à lâcher prise, à ressentir et à transmettre le
                        fluide aux autres…
                    </p>
                    <br/>
                    <h6>Vous aider à vous épanouir</h6>
                    <p>
                        Chaque être vivant est en constant échange d’énergie avec son environnement. Parfois, pour
                        diverses raisons, les vecteurs d’échanges se bloquent, créant des déséquilibres importants. Mes
                        techniques de soins énergétiques et de
                        massages de bien-être sont conçues pour rééquilibrer ces flux énergétiques, dénouer les tensions
                        et restaurer un état de détente et d’harmonie.
                    </p>
                    <br/>
                    <p>
                        J’essaie avant tout de comprendre, d’apaiser et de rétablir les équilibres profonds de l’énergie
                        dans le corps. Une expérience mémorable, l’occasion de se retrouver, être soi-même.
                    </p>
                    <br/>
                    <p id="formations">
                        Au-delà de l’énergie et de l’équilibre, je mets mon action au service d’un certain nombre de
                        valeurs comme l’écoute, l’engagement, le partage…pour permettre une harmonie entre le corps et
                        l’esprit.
                    </p>
                    <h3 className="mt-5">Mes formations</h3>
                    <hr className="mt-3"/>
                </Container>
                <Container>
                    <Row>
                        {certificationsImages.reverse().map((el, i) => <img alt={"formation " + el.name}
                                                                            className="m-4 formation-thumbnail"
                                                                            src={el.picture}/>)}
                    </Row>
                </Container>
            </section>
            <section className="section section-dark pt-2">
                <Container>
                    <div className="owner">
                        <h2 className="title h3">Partenaires</h2>
                        <Row className={"text-left"}>
                            <ul>
                                <li><b>Mademoiselle Violette:</b> <a
                                    href={"https://www.mademoiselleviolette.com/ville/paris"}>https://www.mademoiselleviolette.com</a></li>
                                <li><b>Bulle d'amour:</b> <a
                                    href={"https://www.bulledamour.net"}>https://www.bulledamour.net</a></li>
                                <li><b>Résalib:</b> <a
                                    href={"https://www.resalib.fr/praticien/45046-alix-imhaus-praticien-en-soins-energetiques-boulogne-billancourt"}>https://www.resalib.fr</a></li>
                                <li><b>Wecasa:</b> <a
                                    href={"https://www.wecasa.fr/massage/boulogne-billancourt/alix"}>https://www.wecasa.fr</a></li>
                            </ul>
                        </Row>
                    </div>
                </Container>
            </section>
            <MainFooter/>
        </>
    );
}

export default AboutMePage;
