import {useEffect, useState} from "react";

// reactstrap components
import {
    Col,
    Container, Modal, Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import {Helmet} from "react-helmet";
import {massagesProductList} from "./Products/Data/MassagesData";
import CardProduct from "../components/CardProduct";
import ProductDescriptionComponent from "./Products/components/ProductDescriptionComponent";
import OpeningHoursSectionFooter from "./Products/components/OpeningHoursSectionFooter";
import ProductDescriptionModalFooter from "./Products/components/ProductDescriptionModalFooter";
import {formulesProductList} from "./Products/Data/FormulesData";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import useQuery from "../utils/UseQuery";

const giftCardsHeaderBackground = require("assets/img/backgrounds/future_mom.jpg")

function FutureMomPage() {
    const [modal, setModal] = useState(false);
    const [product, setCurrentProduct] = useState(undefined);
    const navigate = useNavigate();

    const closeModal = () => {
        setModal(false);
        setCurrentProduct(undefined)
    };

    const onModalClosed = () => {
        navigate({
            search: createSearchParams({}).toString()
        });
    };

    let query = useQuery();
    let futureMomProducts = [
        ...massagesProductList.filter((product) => product.id === "massage_prenatal"),
        ...formulesProductList.filter((product) => product.id === "zen_future_mom"),
        ...formulesProductList.filter((product) => product.id === "detox_future_mom"),
        ...formulesProductList.filter((product) => product.id === "bulle_future_mom")
    ]

    useEffect(() => {
        let productId = query.get("pid");
        if (productId) {
            setModal(true);
            setCurrentProduct(futureMomProducts.find(it => it.id === productId))
        }
    }, [query]);

    document.documentElement.classList.remove("nav-open");
    useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    const seoTag = (product) => {
        return (product && (product.titleText || product.title)) || " Massages et Bien-être prénatal."
    };

    return (
        <>
            <Helmet>
                <title>{seoTag(product) + " - Energy Alix - Boulogne Billancourt"}</title>
                <meta name="description"
                      content={seoTag(product) + " - femmes enceintes, Boulogne-Billancourt - Boulogne (31 rue de Fessart, 92100). A domicile et au Cabinet."}/>
                <link rel="canonical" href="https://energy-alix.fr/future-mom"/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={giftCardsHeaderBackground}/>
            <div className="section landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Future maman</h1>
                    </div>
                    <p className="mb-5 text-center">
                        Bienvenue sur mon espace dédié au bien-être des futures mamans. En tant que praticienne de
                        massages spécialisée, je suis là pour offrir des moments de détente et de soulagement adaptés
                        aux besoins uniques de la grossesse.<br/><br/>Mes services de massages bien-être pour femmes enceintes
                        visent à créer une expérience apaisante, favorisant le bien-être physique et émotionnel. Plongez
                        dans un cocon de relaxation où, à travers des techniques douces, je vous accompagne pour vivre
                        pleinement cette période exceptionnelle de votre vie. <br/><br/>Offrez-vous un moment privilégié de
                        connexion avec votre bébé dans un environnement sécurisé et bienveillant. Découvrez comment mes
                        soins personnalisés peuvent contribuer à rendre votre grossesse encore plus douce et sereine.
                    </p>
                    <Row>
                        {
                            futureMomProducts.map((product, i) => {
                                return (
                                    <Col md="4">
                                        <Link to={"/future-mom?pid=" + product.id}>
                                            <CardProduct product={product}></CardProduct>
                                        </Link>
                                    </Col>)
                            })
                        }
                    </Row>
                </Container>
            </div>
            <div className={"section landing-section section-dark"}>
                <Container>
                    <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
                </Container>
            </div>
            <MainFooter/>
            <Modal isOpen={modal} onClosed={onModalClosed} toggle={closeModal} size={"lg"}>
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h5 className="modal-title text-center" id="exampleModalLabel">
                        {product && product.title}
                    </h5>
                </div>
                <div className="modal-body">
                    <ProductDescriptionComponent product={product}></ProductDescriptionComponent>
                </div>
                <OpeningHoursSectionFooter/>
                <ProductDescriptionModalFooter closeModal={closeModal}></ProductDescriptionModalFooter>
            </Modal>
        </>
    );
}

export default FutureMomPage;
