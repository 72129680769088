import React, {useState} from "react";

// reactstrap components
import {Container} from "reactstrap";
import {Animated} from "react-animated-css";
import logo_main from "../../assets/img/logo_main.svg";
import Carousel from "./LandingCarousel";
import TextLoop from "react-text-loop";


// core components

function LandingPageHeader() {
  const [opacity, setOpacity] = useState(1.0);

  let pageHeader = React.createRef();

  React.useEffect(() => {
    const range = window.innerHeight / 2;
    const opacityMin = 0.0;

    const updateOpacityScroll = () => {
      let percentage = Math.abs(1 - (window.scrollY + range) / range);
      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 1) {
        percentage = 1;
      }
      let opacity = 1 - percentage;
      if (opacity < opacityMin) {
        opacity = opacityMin;
      }
      setOpacity(opacity);
    }
    window.addEventListener("scroll", updateOpacityScroll);

    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current) {
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        }
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateOpacityScroll);
        window.removeEventListener("scroll", updateScroll);
      };
    }

    return () => {
      window.removeEventListener("scroll", updateOpacityScroll);
    };
  });


  return (
    <>
      <div
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Carousel></Carousel>
        <div className="filter"/>
        <Container>
          <div className="motto title-logo-container" style={{opacity: opacity}}>
            <Animated animationIn="fadeIn" animationInDuration="7000" isVisible={true}>
              <img alt={"Energy Alix Massages Reflexologie Hypnose Logo"} className="logo-animation" src={logo_main} style={{height: "200px"}}/>
            </Animated>
            <div className="motto">
              <Animated className={"text-center"} animationIn="fadeIn" animationInDuration="5000" animationOut="fadeOut" isVisible={true}>
                <h1 className="h5 presentation-title">Energy Alix</h1>
              </Animated>
              <Animated className={"loop-text"} animationInDelay={2000} animationInDuration="3000" animationIn="fadeIn" isVisible={true}>
                <TextLoop springConfig={{ stiffness: 70, damping: 31 }} interval={5000} noWrap={true} adjustingSpeed={500}>
                  <p className="h3 sub-motto">Bienvenue dans votre bulle de bien-être</p>
                  <p className="h3 sub-motto">Libérez votre esprit, détendez votre corps</p>
                  <p className="h3 sub-motto">Découvrez l'harmonie corporelle.</p>
                </TextLoop>{" "}
              </Animated>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
