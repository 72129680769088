/*eslint-disable*/
import React from "react";
import GoogleMapReact from 'google-map-react'

// reactstrap components
import {mapStyles} from "./MapStyle";

import logo_dark from "../../assets/img/logo_dark.svg";
import InlineSVG from "react-inlinesvg";

const LocationPin = ({addressLine1, addressLine2}) => (
  <>
    <a target="_blank"
       href={"https://www.google.com/maps/dir//Alix+Imhaus-Energy+Alix-R%C3%A9flexologie-Hypnose+corporelle-Hypnose+r%C3%A9gressive-Massage+m%C3%A9tamorphique-S%C3%A9ance+%C3%A9nerg%C3%A9tique-Massage,+31+Rue+Fessart,+92100+Boulogne-Billancourt,+France/@48.8440715,2.2390123,18.92z/data=!3m1!5s0x47e67b1d0cb6826d:0x652d1efba13bf7ed!4m9!4m8!1m0!1m5!1m1!1s0x47e67b8d1ebdb2af:0xeac4852cd6f4c2b5!2m2!1d2.2394933!2d48.8441696!3e0?entry=ttu"}
       className="pin">
      <InlineSVG style={{ height: "5em" }} src={logo_dark}></InlineSVG>
      <p className="map-pin-text">{addressLine1}<br/>{addressLine2}</p>
      <i className="nc-icon nc-tap-01 touch-icon"/>
    </a>
    <i className="nc-icon nc-pin-3 map-pin-icon"/>
  </>
)


function Map({location, zoomLevel}) {

  function createMapOptions(maps) {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: mapStyles
    }
  }

  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          options={createMapOptions}
          bootstrapURLKeys={{key: 'AIzaSyC-vZZbGPbLR78lIfaE-2_zIXu8oJc1yO0'}}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            addressLine1={location.addressLine1}
            addressLine2={location.addressLine2}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default Map;
