/*eslint-disable*/
import React from "react";

// reactstrap components
import {Card} from "reactstrap";

function CardTestimonial({testimonial}) {
  return (
    <Card className="card-testimonial card">
      <div className="card-body"><p className="card-description">"{testimonial.description}"</p>
        <div className="card-footer"><h4 className="card-title">{testimonial.name}</h4>
          <h6 className="card-category">{testimonial.place}</h6>
          <div className="card-stars">
            <i aria-hidden="true" className="fa fa-star mr-1"></i>
            <i aria-hidden="true" className="fa fa-star mr-1"></i>
            <i aria-hidden="true" className="fa fa-star mr-1"></i>
            <i aria-hidden="true" className="fa fa-star mr-1"></i>
            <i aria-hidden="true" className="fa fa-star"></i>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CardTestimonial;
