export const reflexologyProductList = [
  {
    id: "reflexology_plantar",
    tags: ["reflexology"],
    title: "Réflexologie plantaire",
    subtitle: "Décontraction",
    image: require("assets/img/products/reflexology/reflexology_feet.jpg"),
    description: "Favorise le processus d’auto-régulation par un toucher spécifique des zones réflexes et des points de méridiens des pieds.",
    duration: "60 min / 90 min",
    durationEnterprise: "20 min / 30 min",
    descriptionEnterprise: "Technique sur table, massage des pieds qui est fondé sur l’existence de zones réflexes au niveau des pieds, qui représente chaque organe du corps humain.",
    detailedDescription: {
      sessionContent:
        <>
          <p>Vous êtes allongés confortablement habillé et les pieds découverts à votre disposition coussin, plaid.</p>
          <p>Moment de détente des deux pieds, avant de travailler sur un pied puis l’autre en massant les différents sytèmes :</p>
          <p>Nerveux, locomoteurs, musculaires, respiratoires, circulatoires, lymphatiques, digestifs, endocriniens, urinaires, reproducteurs, immunitaires. Ce soin se termine avec la douceur d’une pierre.</p>
          <hr/>
          <h6>Historique:</h6>
          <p>
            La réflexologie est une technique manuelle pratiquée depuis l’Antiquité qui fait partie des médecines naturelles, réputée depuis des millénaires pour avoir la capacité d’agir sur l’ensemble de l’organisme, cette discipline a
            donné naissance à la réflexologie plantaire.
          </p>
          <br/>
          <p>
            La réflexologie plantaire est l’utilisation de techniques de massage et d’acupression sur l’ensemble des pieds, elle étudie l’activité réflexe physique et pathologique précédant une maladie. Cette méthode d’accompagnement est
            fondée sur l’existence de zones réflexes au niveau des pieds, qui représentent chaque organe du corps humain.
          </p>
          <br/>
          <p>
            En effet, le pied est la représentation miniaturisée du corps humain à chaque zone réflexe correspond un organe, une glande ou une partie du corps. L’activation de certains points par simple pression permet d’agir sur les
            organes en déséquilibre ou encrassés afin d’améliorer leur état ou les redynamiser.
          </p>
        </>,
      bienfaits:
        <>
          <li>Il dynamise l’organisme, harmonise les fonctions vitales.</li>
          <li>Il libère le stress et les tensions nerveuses.</li>
          <li>Il améliore la circulation sanguine, apporte relaxation profonde, détente.</li>
          <li>Il active le système lymphatique et décontracte les muscles.</li>
          <li>Il active le système lymphatique et décontracte les muscles.</li>
        </>,
    }
  },
  {
    id: "reflexology_facial",
    tags: ["reflexology"],
    title: "Réflexologie faciale",
    subtitle: "Anti-stress",
    image: require("assets/img/products/reflexology/reflexology_face.jpg"),
    description: "Favorise le processus d’auto-régulation par un toucher spécifique des zones réflexes et des points de méridiens du visage.",
    duration: "60 min / 90 min",
    descriptionEnterprise: "Technique sur table, massage de la tête qui est aussi fondé comme la réflexologie plantaire concerné le visage et la tête.",
    durationEnterprise: "20 min / 30 min",
    detailedDescription: {
      sessionContent:
        <>
          <p>Vous êtes allongés confortablement (table de massage, plaid) , j’utilise les doigts pour exercer des points de pression ou des lissages sur les zones réflexes <b>sans huile*</b> (front, visage, crâne), qui correspondent à un
            organe ou à des parties du corps. </p>
          <p><small>* sur demande huile d’abricot</small></p>
        </>,
      bienfaits:
        <>
          <p>La réflexologie faciale et crânienne possède de nombreuses vertus. Elle procure une profonde sensation de détente physique et psychique, pour entretenir la bonne santé.</p>
          <br/>
          <p>
            Le visage est très richement vascularisé, 300 terminaisons nerveuses, la tête fait partie du systèmes sympathique, une action direct sur le cerveau car les informations sont directement transmises au système nerveux central pour
            qu’il rétablisse la fonction des organes.
          </p>
          <br/>
          <p>On a une action sur 11 muscles du visage en utilisant l’influx nerveux. On a une action sur l’état physique, émotionnel et psychologique de la personne.</p>
          <p>Au-delà de son action apaisante, elle soulage également certaines douleurs :</p>
          <ul>
            <li>Diminue les douleurs dorsales et articulaires</li>
            <li>Calme les céphalées</li>
            <li>fatigue général, lassitude</li>
            <li>Inconfort intestinal, maux de ventre</li>
            <li>Douleur musculaire, dos, nuque</li>
            <li>Meilleure qualité du sommeil</li>
            <li>Tension nerveuse, surmenage</li>
            <li>Elimination la suractivité mentale</li>
            <li>Régulation de la circulation sanguine</li>
            <li>Une action sur les blessures comportementale et émotionnelles par la morpho-typologie</li>
            <li>Grâce à son action direct sur le cerveau et les glandes endocrines, on rééquilibre les plans physiologiques , émotionnel et psychologiques de ka personnes</li>
            <li>Les stimulis sur la peau vont décongestionner les zones encombrées et expulsé les toxines accumulées sur ma peau vont décongestionner les zones encombrées et expulser les toxines, accumulées qui ralentissent le
              fonctionnement des organes et des cellules car la peau est le premier organe de l’excrétion des toxines
            </li>
            <li>Apaise les troubles pré-menstruels et liés à la ménauposer</li>
            <li>Détente des muscles du visage, une meilleur qualité des tissus</li>
            <li>Redonne éclat et élasticité à la peau</li>
            <li>Stimule la micro-circulation</li>
          </ul>

          <p>Attention : la réflexologie faciale n’est pas reconnue par la médecine traditionnelle. Si certains maux persistent ou entraînent de vraies incapacités, une consultation avec un professionnel de santé s’impose.</p>
        </>,
      counterIndications:
        <>
          <ul>
            <li>Femmes enceintes de moins de 4 mois</li>
            <li>Troubles circulatoires (phlébite, varice, trombose…)</li>
            <li>Pathologies : cancer, épilepsie, uniquement sur l’accord du médecin traitant</li>
          </ul>

          <i>En cas de doute : <br/>
            Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>

          <p>Idéalement séances espacées de 15 jours pour réguler, et voir les effets.</p>
        </>,
    }
  }
]
