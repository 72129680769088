/*eslint-disable*/
import React from "react";


function CardPricing({pricing}) {
  return (
    <>
      <div className="card card-pricing card-gift" data-background="image" style={{backgroundImage: "url(" + pricing.image + ")"}}>
        <div className="card-body">
          <h3 className="card-category">{pricing.title}</h3>
          <h6 className="card-title">Tarifs {new Date().getFullYear()}</h6>
        </div>
      </div>

    </>
  );
}

export default CardPricing;
