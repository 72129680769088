import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col, Modal,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import CardPricing from "../components/CardPricing";
import ProductDescriptionModalFooter from "./Products/components/ProductDescriptionModalFooter";
import OpeningHoursSectionFooter from "./Products/components/OpeningHoursSectionFooter";
import {Helmet} from "react-helmet";

const pricingPageHeaderBack = require("assets/img/backgrounds/pricing.jpg")
const pricingListData = [
  {
    title: "Particuliers",
    image: require("assets/img/pricings/particuliers.jpg"),
    imageModal: require("assets/img/pricings/particuliers_brochure.jpg")
  },
  {
    title: "Enfants",
    image: require("assets/img/pricings/kids.jpg"),
    imageModal: require("assets/img/pricings/enfants_brochure.jpg")
  },
  {
    title: "Formules",
    image: require("assets/img/pricings/packages.jpg"),
    imageModal: require("../assets/img/pricings/packages_brochure.jpg")
  },
  {
    title: "Evènements",
    image: require("assets/img/pricings/companies.jpg"),
    modalBody: <>
      <p>Ajoutez à votre événement une touche de bien-être et de détente inoubliable. Je vous propose des massages relaxants, des soins holistiques revitalisants et des sessions d'hypnose apaisantes, offrant à vos invités une expérience
        unique et réparatrice.</p>
      <p>
        <ul>
          <li>Enterrement de vie de jeune fille</li>
          <li>Évènement d'entreprise</li>
        </ul>
      </p>
      <p>
        Me contacter directement pour toute information relative à l'organisation de votre évènement.
      </p>
    </>
  }
]

function PricingPage() {
  const [modal, setModal] = React.useState(false);
  const [pricing, setCurrentPricing] = React.useState(undefined);
  const closeModal = () => {
    setModal(false);
    setCurrentPricing(undefined)
  };

  const openModal = (pricing) => {
    setModal(!modal);
    setCurrentPricing(pricing)
  };


  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <Helmet>
        <title>Tarifs - Energy Alix - Massages, Réflexologie et Hypnose à Boulogne</title>
        <meta name="description" content="Tarifs des prestations. Massages, soins holitsiques et hypnose. A domicile et au cabinet: 31 rue de Fessart, 92100 Boulogne-Billancourt"/>
      </Helmet>
      <DefaultNavbar/>
      <DefaultPageHeader backgroundImage={pricingPageHeaderBack}/>
      <div className="section landing-section">
        <Container>
          <div className="owner">
            <h2 className="title mb-5">Les Tarifs</h2>
            <Row>
              {pricingListData.map((pricing, i) => {
                return (
                  <Col md="12" onClick={() => openModal(pricing)}>
                    <CardPricing pricing={pricing}></CardPricing>
                  </Col>)
              })
              }
            </Row>
          </div>
        </Container>
      </div>
      <div className={"section landing-section section-dark"}>
        <Container>
          <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={closeModal} size={"lg"}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-center" id="exampleModalLabel">
            {pricing && pricing.title}
          </h5>
        </div>
        <div className="modal-body" style={pricing && pricing.imageModal && {backgroundColor: "#112d5d"}}>
          {pricing && pricing.modalBody}
          {pricing && pricing.imageModal && (<img
            alt={"Prix " + pricing.title}
            className="img-container img-no-padding img-responsive"
            src={pricing.imageModal}
          />)}
        </div>
        <ProductDescriptionModalFooter closeModal={closeModal} ctaEmail></ProductDescriptionModalFooter>
      </Modal>
      <MainFooter/>
    </>
  );
}

export default PricingPage;
