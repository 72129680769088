import React from "react";

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar.js";
import {Helmet} from "react-helmet";

function NotFoundPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    });
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://energy-alix.fr/not-found"/>
            </Helmet>
            <DefaultNavbar/>
            <div
                className="page-header"
                style={{
                    backgroundImage: "url(" + require("assets/img/backgrounds/not_found.jpg") + ")",
                }}
            >
                <div className="filter"/>
                <p className="h1 ml-n5">Page Not Found</p>
            </div>
        </>
    );
}

export default NotFoundPage;
