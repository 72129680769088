export const formationsProductList = [
  {
    title: "Formation au Massage Métamorphique",
    subtitle: "Soin Holistique",
    image: require("assets/img/products/formations/formation_massage_metamorphique.jpg"),
    description: "Méthode de toucher doux appliqué aux pieds, aux mains et à la tête, visant à libérer des blocages émotionnels et énergétiques pour favoriser un processus de transformation intérieure.",
    duration: "1 Journée",
    date: "A venir",
    detailedDescription: {
      customContent:
        <>
          <p>
            Le soin LaHoChi est un soin utilisant une très haute fréquence énergétique dite « christique ».<br/>
            Sa simplicité et sa puissance ont pour vocation de venir soulager les maux du corps et de l’esprit.<br/>
            Le LaHoChi est pratiqué par un praticien initié qui permet par apposition des mains une harmonisation de tous les plans de l’être, aussi bien au niveau physique, mental, qu’émotionnel. Le LaHoChi, permet d’équilibrer les corps
            énergétiques, les chakras, ainsi que le circuit des méridiens.
          </p>
          <hr/>
          <p>
            <b>Programme:</b>
            <br/><br/>
            <ol>
              <li>Importance des pieds à travers l’Histoire.</li>
              <li>Origines du soin métamorphique</li>
              <li>Fondements du soin métamorphique</li>
              <li>Bienfaits du soin métamorphique</li>
              <li>Sur qui pratiquer le soin métamorphique</li>
              <li>Attitude du praticien en soin métamorphique</li>
              <li>Le protocole de soin métamorphique</li>
            </ol>
          </p>
          <hr/>
          <h6>A savoir :</h6>
          <p>
            Pendant cette journée, vous allez être en binôme pour recevoir le protocole et le donner pour ressentir ce qui se passe et comprendre avec vos sens.
          </p>
          <p>
            Horaire : 9h15/17h30
          </p>
          <p>L’empathie est primordial pour chaque personne qui recevra le toucher métamorphique</p>
          <br/>
          <p>
            <b>Tarif :</b> 170 euros / Inscriptions à 2: 300 euros
          </p>
        </>,
    }
  },
  {
    title: "Formation au soin LaHoChi",
    subtitle: "Soin Energetique",
    image: require("assets/img/products/formations/formation_lahochi.jpg"),
    description: "Technique de guérison énergétique basée sur le transfert de l'énergie universelle par imposition des mains, visant à rééquilibrer et à harmoniser les différents niveaux de l'être.",
    duration: "1 Journée",
    date: "A venir",
    detailedDescription: {
      customContent:
        <>
          <h6></h6>
          <p>
            C’est un soin holistique pratiqué de manière courante dans les hôpitaux anglais et reconnu d’utilité publique en Grande Bretagne.
            La technique métamorphique se pratique par effleurements très doux de la ligne réflexe vertébrale située au niveau des pieds, des mains et de la tête.
            Le soin permet un travail sur les mémoires cellulaires anciennes du corps, et favorise la libération des blocages psycho-énergétiques et corporels qui nous empêchent d’avancer, de construire, d’être.
          </p>
          <hr/>
          <p>
            <b>Les atouts:</b>
            <br/><br/>
            <ul>
              <li>Auto-traitements LaHoChi pour votre bien-être mental et physique.</li>
              <li>Soin LaHoChi pour vos proches ou vos clients</li>
              <li>Soin à distance LaHoChi pour vos proches ou vos clients</li>
              <li>Nettoyage énergétique d’un lieu</li>
            </ul>
          </p>
          <hr/>
          <h6>A savoir :</h6>
          <p>
            Le LaHoChi n’appartient à aucun dogme et se pratique à l’aide d’une prière. Il est accessible à tous quelque soient le niveau ou les connaissances. C’est à force de pratique que chaque personne pourra amplifier la force du soin.
          </p>
          <p>
            <ul>
              <li>Manuel d’initiation</li>
              <li>Certificat d’initiation (après avoir procédé au 21 jours d’auto-traitement et avoir pratiqué un minimum de 5 soins sur autrui.)</li>
            </ul>
          </p>
          <br/>
          <p>
            <b>Tarif :</b> 150 euros / Inscriptions à 2: 250 euros
          </p>
        </>,
    }
  }
]
