import {useEffect, useState} from "react";

// reactstrap components
import {
  Col,
  Container, Modal, Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import ProductDescriptionComponent from "./Products/components/ProductDescriptionComponent";
import ProductDescriptionModalFooter from "./Products/components/ProductDescriptionModalFooter";
import CardProduct from "../components/CardProduct";
import {formulesProductList} from "./Products/Data/FormulesData";
import OpeningHoursSectionFooter from "./Products/components/OpeningHoursSectionFooter";
import {Helmet} from "react-helmet";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import useQuery from "../utils/UseQuery";

const packagesHeaderBackground = require("assets/img/backgrounds/packages.jpg")

function PackagesPage() {
  const [modal, setModal] = useState(false);
  const [product, setCurrentProduct] = useState(undefined);
  const navigate = useNavigate();

  const closeModal = () => {
    setModal(false);
    setCurrentProduct(undefined)
  };

  const onModalClosed = () => {
    navigate({
      search: createSearchParams({}).toString()
    });
  };

  let query = useQuery();

  useEffect(() => {
    let productId = query.get("pid");
    if (productId) {
      setModal(true);
      setCurrentProduct(formulesProductList.find(it => it.id === productId))
    }
  }, [query]);

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const seoTag = (product) => {
    return (product && (product.titleText || product.title)) || " Massages Energétiques et Hypnose"
  };

  return (
    <>
      <Helmet>
        <title>{"Formule " + seoTag(product) + " - Energy Alix - Boulogne Billancourt"}</title>
        <meta name="description" content={"Formule " + seoTag(product) + " Boulogne-Billancourt - Boulogne (31 rue de Fessart, 92100). A domicile et au Cabinet."}/>
        <link rel="canonical" href="https://energy-alix.fr/packages"/>
      </Helmet>
      <DefaultNavbar/>
      <DefaultPageHeader backgroundImage={packagesHeaderBackground}/>
      <div className="section landing-section">
        <Container>
          <div className="owner">
            <h1 className="title mb-5 h2">Les Formules</h1>
            <p className="mb-5">
              Optez pour une formule, combinant massages et soins holistiques pour vous offrir une approche complète du bien-être. En effectuant des massages spécifiques visant à détendre les muscles et à réduire le stress, tout en intégrant des techniques holistiques pour équilibrer le corps, l'esprit et l'âme, vous favorisez la régénération à plusieurs niveaux, améliorant la circulation, apaisant l'esprit et élevant le niveau d'énergie. <br/><br/>Ces formules visent à revitaliser l'individu dans sa totalité, promouvant ainsi un état de santé global optimal.
            </p>
            <Row>
              {formulesProductList.map((product, i) => {
                return (
                  <Col md="4">
                    <Link to={"/packages?pid=" + product.id}>
                      <CardProduct product={product}></CardProduct>
                    </Link>
                  </Col>)
              })
              }
            </Row>
          </div>
        </Container>
      </div>
      <div className={"section landing-section section-dark"}>
        <Container>
          <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
        </Container>
      </div>
      <MainFooter/>
      <Modal isOpen={modal} onClosed={onModalClosed} toggle={closeModal} size={"lg"}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-center" id="exampleModalLabel">
            {product && product.title}
          </h5>
        </div>
        <div className="modal-body">
          <ProductDescriptionComponent product={product}></ProductDescriptionComponent>
        </div>
        <OpeningHoursSectionFooter></OpeningHoursSectionFooter>
        <ProductDescriptionModalFooter closeModal={closeModal}></ProductDescriptionModalFooter>
      </Modal>
    </>
  );
}

export default PackagesPage;
