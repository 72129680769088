import React from "react";

// reactstrap components
import {
    Button,
    Col,
    Container, Row,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import CardGiftCard from "../components/CardGiftCard";
import {giftCardList} from "./Products/Data/GiftCardsData";
import InlineSVG from "react-inlinesvg";
import icon_phone_call from "../assets/icons/phone-call.svg";
import {Helmet} from "react-helmet";

const giftCardsHeaderBackground = require("assets/img/backgrounds/gift_cards_back.jpg")

function GiftCardsPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <Helmet>
                <title>Cartes Cadeaux - Energy Alix - Massages, Réflexologie et Hypnose à Boulogne Billancourt</title>
                <meta name="description"
                      content="Cartes Cadeaux pour prestations massages, soins holitsiques et hypnose. A domicile et au cabinet: 31 rue de Fessart, 92100 Boulogne-Billancourt"/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={giftCardsHeaderBackground}/>
            <div className="section landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Cartes Cadeaux</h1>
                        <Row>
                            {giftCardList.map((gift, i) => {
                                return (
                                    <Col md="4">
                                        <CardGiftCard gift={gift}></CardGiftCard>
                                    </Col>)
                            })
                            }
                        </Row>
                        <br className={"my-2"}/>
                        <p>
                            L'achat de 3 cartes donne une remise de <b>15%</b> sur la quatrième carte cadeau.
                            <br/>
                            Prenez rendez-vous pour profiter de ce moment détente.
                        </p>
                        <br className={"my-2"}/>
                        <h3>Passer Commande</h3>
                        <br className={"my-2"}/>
                        <Row>
                            <Col lg="4">
                                <Button
                                    className="btn-round"
                                    color="success"
                                    href="mailto:energy.alix@gmail.com"
                                    target="_blank"
                                >
                                    <i className="nc-icon nc-email-85 pr-3"/>
                                    energy.alix@gmail.com
                                </Button>
                            </Col>
                            <Col lg="4">
                                <Button
                                    className="btn-round"
                                    color="success"
                                    outline
                                    href="tel:+33766304547"
                                    target="_blank"
                                >
                                    <InlineSVG className="mx-2" style={{width: "1.1em", height: "1.1em"}}
                                               src={icon_phone_call}></InlineSVG>
                                    07 66 30 45 47
                                </Button>
                            </Col>
                        </Row>
                        <br className={"my-4"}/>
                        <p className="card-description text-center">
                            La carte cadeau Energy Alix est valable 9 mois à compter de sa date d'activation. La date
                            d'activation est définie comme étant la date d'achat mais peut sur demande être modifiée à
                            la date d'un évènement (Noel, anniversaire
                            etc…). Le règlement s’effectue en espèces, par chèque ou par virement bancaire.
                            La carte vous sera remise dès réception de votre règlement soit en main propre, soit par
                            courrier.
                            <br/>
                            <br/>
                            La carte cadeau Energy Alix est nominative. La Carte Cadeau Energy Alix ne peut être ni
                            échangée, ni revendue, ni remboursée, même partiellement, ni portée au crédit d'une carte ou
                            d'un compte bancaire ou d'une autre carte
                            cadeau, ni faire l'objet d'un escompte et ne peut donner lieu à aucun rendu de monnaie. Elle
                            n'est ni remplacée, ni remboursée en cas de perte.
                            <br/>
                        </p>
                        <br className={"my-4"}/>
                        <p>
                            La carte cadeaux est valable sur l'ensemble des prestations proposées:
                            <br/>
                            <br/>
                            <b>Au cabinet</b> les lundis, mercredis, jeudis et samedis.<br/>
                            <b>A domicile</b> ( Boulogne, Sèvres, Issy le Moulineaux, Saint Cloud, Meudon, Paris 15eme,
                            Neuilly, Suresnes, Garches..) <b><u>prévoir les frais de déplacements 15 euros</u></b>
                        </p>
                    </div>
                </Container>
            </div>
            <MainFooter/>
        </>
    );
}

export default GiftCardsPage;
