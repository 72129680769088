export const holisticAnimalsProductList = [
  {
    id: "energetic_animals",
    tags: ["holistic_animals"],
    title: "Soin énergétique animaux",
    subtitle: "Equilibre",
    image: require("assets/img/products/holistic/animals_background.jpg"),
    description: "Vise à rééquilibrer leurs énergies et favoriser leur bien-être physique et émotionnel.",
    duration: "60 min",
    detailedDescription: {
      sessionContent:
        <>
          <p>Prise de contact avec votre compagnon animal en présentiel ou à distance photo (nom, date de naissance, sexe..)</p>
          <p>Son objetif est d’éléver et d’équilibrer l’énergie vitale pour <b>faciliter le bien-être</b> sur le plan <b>physique, mentale, affectif</b>, un moment bien-être pour votre compagnon.</p>
          <br/>
          <br/>
          <h6>Soins énergétiques:</h6>
          <p>
            Le corps de <b>l’animal</b> fonctionne comme celui de <b>l’humain</b>.
          </p>
          <br/>
          <p>Un soin énergétique consiste en un nettoyage et un réalignement du système énergétique.</p>
          <br/>
          <p>
            Le système énergétique est composé des chakras, des corps subtils ainsi que de plusieurs circuits et tissus subtils. Le soin permet d’intervenir sur les blocages, de nettoyer les surcharges et les cristallisations puis de
            dégager toutes formes de stagnation qui entravent le fonctionnement de votre système énergétique.</p>
          <br/>
          <p>
            Les chakras sont les centres énergétiques dans le corps physique qui sont connectés aux glandes et au système nerveux servant à métaboliser et distribuer l’énergie universelle dans celui-ci. Les corps subtils sont les
            différentes couches vibratoires qui englobent et interpénètrent le corps physique. Les circuits et tissus subtils relient les chakras aux corps subtils. Ils permettent à l’énergie de circuler et de nourrir toute la structure
            énergétique, ainsi que vos organes, vos glandes et votre système nerveux.
          </p>
          <br/>
          <p>
            Le traitement prodigué aux chakras et aux corps subtils a une influence directe sur le corps physique et l’entièreté de ce qui le compose. Les organes, les muscles, les tendons, les os, le système nerveux, sanguin et hormonal
            bénéficient tous d’une forme de douche énergétique qui invite tout ce qui est stagnant à être mis en mouvement et ce qui est sans vigueur à être nourri. L’être dans sa globalité retrouve une fluidité circulatoire de tous ses
            systèmes.
          </p>
          <br/>
          <p>
            Tous les plans d’un être étant intimement liés, le nettoyage énergétique a aussi une influence
            directe sur plan émotionnel et mental. Les accumulations et les tensions seront relâchées. Les
            blocages qui sont prêts à être libérés le seront, tout comme les mécanismes limitatifs et les
            programmations désuètes seront mises à jour.
          </p>
          <br/>
          <p>
            En terme de symptômes, les séances permettent de calmer le stress et l’anxiété. Ils contribuent à
            diminuer les tensions musculaires, les troubles digestifs et du sommeil, pour ne nommer que
            ceux-ci. Ils sont également efficaces pour nettoyer les mémoires corporelles, c’est-à-dire, les
            chocs emmagasinés par le corps lors de situations difficiles depuis la naissance. De plus, les
            séances énergétiques permettent d’installer une qualité d’enracinement.
          </p>
          <br/>
          <p>
            Tout cela crée une incidence sur les comportements et les réactions, ouvrant l’être à de
            nouveaux horizons personnels et relationnels. Tous les systèmes se relâchent et se bonifient, ainsi l’être dans sa globalité retrouve un état d’harmonie et d’ouverture qui le dispose à recevoir
            la vie et la transformation
          </p>
          <br/>
          <p>
            Voici quelques exemples des problèmes physiques que peuvent résoudre les soins énergétiques
            à distance comme les soins énergétiques à domicile : troubles alimentaires, troubles du sommeil,
            problèmes de peau, douleurs chroniques, fatigue persistante, addictions, stress, peur, phobies…
          </p>
        </>,
      bienfaits:
        <>
          <ul>
            <li>Apporter un équilibre au niveau énergétique et vibratoire</li>
            <li>Harmoniser le corps et l’esprit</li>
            <li>Aider à libérer l’énergie: libérer les blocages, les tensions</li>
            <li>Soulager les douleurs</li>
            <li>Un moment d’apaisement, de profonde détente</li>
            <li>Ainsi que bien d'autres effets positifs sur le bien êtrephysique et émotionnel</li>
          </ul>
        </>,
    }
  }
]
