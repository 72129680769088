export const GeneralTestimonials = [
  {
    description: "Expérience inédite, Alix m'a accompagnée avec une bienveillance particulière. Des résultats incroyables…. la mise en évidence de maux que je n'ai pas évoqués. Il faut faire confiance ... Je vous recommande vivement ce thérapeute.",
    name: "Nelly",
    place: "Paris"
  },
  {
    description: "Alix est une masseuse au top, elle est professionnelle, connaît parfaitement son métier. Je l'avais sollicité pour un massage pré natal, je lui ai fait confiance du début jusqu'à la fin. Je recommande les yeux fermés",
    name: "Laurie",
    place: "Issy-les-Moulineaux"
  },
  {
    description: "Une belle et agréable rencontre. Alix est très professionnelle et fais preuve d'une grande écoute et générosité. J'ai été ravie de cette rencontre. Et j'en suis sortie, légère et sereine. Je recommande vivement ces soins. Et j'y reviendrai.",
    name: "Maud",
    place: "Boulogne-Billancourt"
  },
]

export const MassagesTestimonials = [
  {
    description: "Une amie m'a recommandée Alix pour une séance de réflexologie plantaire. Je ne le regrette absolument pas. C'est une grande professionnelle à l'écoute de ses patients.",
    name: "Élodie",
    place: "Paris"
  },
  {
    description: "Magnifique massage, un grand moment de complicité avec mon bébé grace à Alix",
    name: "Clara",
    place: "Boulogne-Billancourt"
  },
  {
    description: "Alix est une masseuse redoutable. Avec elle le stress et la fatigue disparaissent de manière impressionnante.",
    name: "Marc",
    place: "Nanterre"
  }
]

export const HolisticTestimonials = [
  {
    description: "Cela fait 3 jours que je suis passée à son cabinet, et je peux lui dire un GRAND MERCI. Le massage métamorphique m'est d'une grande utilisé puisque j'en ressens déjà les bienfaits. Une étape primordiale, alternative au chimique qui m'aide à avancer dans ma vie. Allez y les yeux fermés...",
    name: "Cindy",
    place: "Boulogne-Billancourt"
  },
  {
    description: "Idéal pour ceux qui ont besoin de se libérer des tourbillons de pensées et d'une surcharge d activité cérébrale",
    name: "Samuel",
    place: "Paris"
  },
  {
    description: "Bonjour J'ai beaucoup aimé la séance énergétique qui m'a requinqué. J'ai apprécié le soin et le professionnalisme d'Alix que je recommande chaudement.",
    name: "Sasa",
    place: "Boulogne-Billancourt"
  }
]

export const HypnosesTestimonials = [
  {
    description: "Je me suis offert une séance complète de bodhypnosis sous les mains d'alix au top * que de belles libérations subtiles comme si certaines mémoires du corps se sont autorisées à partir.",
    name: "Olivier",
    place: "Paris"
  },
  {
    description: "Grâce à Resalib, désormais prendre rdv avec Alix est encore plus rapide et simple. Les prestations sont toujours parfaites et la nouveauté \"hypnose corpelle\" est magique, elle permets immédiatement de se relaxer et d'évacuer le stress",
    name: "Murielle",
    place: "Suresnes"
  }
]
