
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import {Link} from "react-router-dom";

function MainFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <Link to="/legal">
                  Mentions Légales
                </Link>
                <Link to="/about-me">
                  À Propos
                </Link>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()} Energy-Alix, made with{" "}
              <i className="fa fa-heart heart" /> by G.M.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
