import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col, Modal,
} from "reactstrap";

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar.js";
import MainFooter from "../../components/Footers/MainFooter.js";
import CardProduct from "../../components/CardProduct";
import ProductDescriptionComponent from "./components/ProductDescriptionComponent";
import ProductDescriptionModalFooter from "./components/ProductDescriptionModalFooter";
import DefaultPageHeader from "../../components/Headers/DefaultPageHeader";
import {HypnosesTestimonials} from "./Data/TestimonialsData";
import TestimonialsSection from "../Sections/TestimonialsSection";
import OpeningHoursSectionFooter from "./components/OpeningHoursSectionFooter";
import {Helmet} from "react-helmet";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import {allProductsList} from "./Data/AllProductsData";
import useQuery from "../../utils/UseQuery";

function HypnosePage() {

    const [modal, setModal] = React.useState(false);
    const [product, setCurrentProduct] = React.useState(undefined);
    const navigate = useNavigate();

    const closeModal = () => {
        setModal(false);
        setCurrentProduct(undefined)
    };

    const onModalClosed = () => {
        navigate({
            search: createSearchParams({}).toString()
        });
    };

    let query = useQuery();
    let pagesProducts = allProductsList.filter(it => it.tags?.indexOf("hypnosis") > -1);

    React.useEffect(() => {
        let productId = query.get("pid");
        if (productId) {
            setModal(true);
            setCurrentProduct(pagesProducts.find(it => it.id === productId))
        }
    }, [query]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    const seoTag = (product) => {
        return (product && (product.titleText || product.title)) || "Hypnose"
    };

    return (
        <>
            <Helmet>
                <title>{seoTag(product) + " - Energy Alix - Boulogne Billancourt"}</title>
                <meta name="description" content={seoTag(product) + " Boulogne-Billancourt - Boulogne (31 rue de Fessart, 92100). A domicile et au Cabinet."}/>
                <link rel="canonical" href="https://energy-alix.fr/hypnose"/>
            </Helmet>
            <DefaultNavbar/>
            <DefaultPageHeader backgroundImage={require("../../assets/img/products/hypnose/header_background.jpg")}/>
            <div className="section landing-section">
                <Container>
                    <div className="owner">
                        <h1 className="title mb-5 h2">Hypnoses</h1>
                        <Row>
                            {pagesProducts.map((product, i) => {
                                return (
                                    <Col md="4">
                                        <Link to={"/hypnose?pid=" + product.id}>
                                            <CardProduct product={product}></CardProduct>
                                        </Link>
                                    </Col>)
                            })
                            }
                            <Modal isOpen={modal} onClosed={onModalClosed} toggle={closeModal} size={"lg"}>
                                <div className="modal-header">
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        type="button"
                                        onClick={() => closeModal()}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                    <h5 className="modal-title text-center" id="exampleModalLabel">
                                        {product && product.title}
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <ProductDescriptionComponent product={product}></ProductDescriptionComponent>
                                </div>
                                <OpeningHoursSectionFooter/>
                                <ProductDescriptionModalFooter closeModal={closeModal}></ProductDescriptionModalFooter>
                            </Modal>
                        </Row>
                    </div>
                </Container>
            </div>
            <TestimonialsSection testimonials={HypnosesTestimonials} dark></TestimonialsSection>
            <div className={"section landing-section"}>
                <Container>
                    <OpeningHoursSectionFooter transparent></OpeningHoursSectionFooter>
                </Container>
            </div>
            <MainFooter/>
        </>
    );
}

export default HypnosePage;
