import React from "react";

// reactstrap components
import {UncontrolledCarousel} from "reactstrap";

// const back1 = require("assets/img/backgrounds/stars_background.jpg")
const back1 = require("assets/img/backgrounds/stars_background.jpg")
const back2 = require("assets/img/backgrounds/massage_back_2.jpg")
const back3 = require("assets/img/backgrounds/massage_back_5.jpg")
const back4 = require("assets/img/backgrounds/massage_back_4.jpg")

const carouselItems = [
  {
    src: back1,
  },
  {
    src: back2,
  },
  {
    src: back3,
  },
  {
    src: back4,
  }
];

function Carousel() {
  return (
    <>
      <UncontrolledCarousel className="carousel-fade" interval="6000" items={carouselItems} autoPlay={true} slide={false} controls={false}/>
    </>
  );
}

export default Carousel;
