import {formulesProductList} from "./FormulesData";
import {massagesProductList} from "./MassagesData";
import {holisticProductList} from "./HolisticData";
import {hypnoseProductList} from "./HypnoseData";
import {reflexologyProductList} from "./ReflexologyData";
import {holisticAnimalsProductList} from "./HolisticAnimalsData";

export const allProductsList = [
    ...formulesProductList,
    ...massagesProductList,
    ...holisticProductList,
    ...holisticAnimalsProductList,
    ...hypnoseProductList,
    ...reflexologyProductList
]
