import {Col, Row} from "reactstrap";
import React from "react";
import icon_home from "../../../assets/icons/home.svg";
import icon_cabinet from "../../../assets/icons/cabinet.svg";
import InlineSVG from "react-inlinesvg";

function OpeningHoursSectionFooter({transparent}) {
  return (
    <div className={transparent ? "text-center" : "modal-section-dark text-center"}>
      <Row>
        <Col lg="5">
          <h4><small><InlineSVG style={{height: "50px"}} className={"mt-n1"} src={icon_cabinet}></InlineSVG>Au Cabinet</small></h4>
          <a className="link-gmap" target="_blank" href={"https://www.google.com/maps/dir//Alix+Imhaus-Energy+Alix-R%C3%A9flexologie-Hypnose+corporelle-Hypnose+r%C3%A9gressive-Massage+m%C3%A9tamorphique-S%C3%A9ance+%C3%A9nerg%C3%A9tique-Massage,+31+Rue+Fessart,+92100+Boulogne-Billancourt,+France/@48.8440715,2.2390123,18.92z/data=!3m1!5s0x47e67b1d0cb6826d:0x652d1efba13bf7ed!4m9!4m8!1m0!1m5!1m1!1s0x47e67b8d1ebdb2af:0xeac4852cd6f4c2b5!2m2!1d2.2394933!2d48.8441696!3e0?entry=ttu"}>
            <p className="map-pin-text"><i className="nc-icon nc-tap-01 touch-icon"/> 31 rue de Fessart 92100 Boulogne-Billancourt</p>
          </a>
          <p className={"opening-hours"}>
            <div><span className={"opening-day"}>Lundi</span><span className={"opening-hour"}>08:00 - 19:00</span></div>
            <div><span className={"opening-day"}>Mercredi</span><span className={"opening-hour"}>08:30 - 20:30</span></div>
            <div><span className={"opening-day"}>Jeudi</span><span className={"opening-hour"}>08:00 - 19:30</span></div>
            <div><span className={"opening-day"}>Samedi</span><span className={"opening-hour"}>09:00 - 20:00</span></div>
          </p>
        </Col>
        <Col lg="7">
          <h4><small><InlineSVG style={{height: "50px"}} className={"mt-n1"} src={icon_home}></InlineSVG> À domicile</small></h4>
          <p className={"opening-hours"}>
            Possibilité de déplacements pour les jours hors cabinet.
            <br/>
            <span><i className="nc-icon nc-pin-3"/> <span style={{fontSize: "0.85rem"}} className={"font-italic"}> Boulogne, Sèvres, Issy le Moulineaux, Saint Cloud, Meudon, Paris 15eme, Neuilly, Suresnes, Garches... </span><br/><small>prévoir des frais de déplacements jusqu'à 15 euros.</small></span>
          </p>
        </Col>
      </Row>
    </div>
  );
}

// Setting default value for props
OpeningHoursSectionFooter.defaultProps = {
  transparent: false
};

export default OpeningHoursSectionFooter;
