import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DefaultNavbar from "../components/Navbars/DefaultNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import DefaultPageHeader from "../components/Headers/DefaultPageHeader";
import {Helmet} from "react-helmet";

const legalPageHeaderBack = require("assets/img/backgrounds/legal.jpg")

function LegalPage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <Helmet>
        <title>Mentions Legales - Energy Alix - Massages, Réflexologie, Hypnose à Boulogne Billancourt</title>
        <meta name="description" content="Mentions légales. Votre bulle de Bien-être à Boulogne-Billancourt. Massages, soins holitsiques et hypnose. A domicile et au cabinet: 31 rue de Fessart, 92100 Boulogne-Billancourt"/>
      </Helmet>
      <DefaultNavbar/>
      <DefaultPageHeader backgroundImage={legalPageHeaderBack}/>
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="Energy Alix logo"
                className="img-circle img-no-padding img-responsive img-logo"
                style={{
                  width: "100px",
                }}
                src={require("assets/img/logo_light.png")}
              />
            </div>
            <div className="name">
              <h4 className="title">
                Energy Alix <br/>
              </h4>
              <h6 className="description">Alix Imhaus</h6>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p>
                <b>Statut</b>: Auto-entrepreneur/ Entreprise Individuel
              </p>
              <p>
                <b>N°Siret</b>: 899301105000016
              </p>
              <p>
                <b>Tél</b> : <a href="tel:+33766304547">07 66 30 45 47</a>
              </p>
              <p><b>Site web</b>: https://energy-alix.fr</p>
              <p><b>E-mail</b>: energy.alix@gmail.com</p>
              <p><b>Siège</b>: 5 rue Pau casals - 92100 Boulogne</p>
            </Col>
          </Row>
          <br/>
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Conditions générales de ventes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Données et confidentialité
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="cgv" activeTab={activeTab}>
            <TabPane tabId="1" id="cgv">
              <Container>
                <Row>
                  <Col md="12">
                    <h5>Prestations proposées sur ce site :</h5>
                    <ul>
                      <li>Approche holistique : Lahochi, Soin énergétique, Massages métamorphiques</li>
                      <li>Hypnose : Hypnose régressive spirituelle énergétique, Hypnose Corporelle (Bodhypnosis)</li>
                      <li>Massages bien-être et réflexologie plantaire/faciale</li>
                    </ul>
                    Le terme « massage » est employé sur le site pour une meilleure compréhension.
                    Les prestations ne sont en aucune manière des actes thérapeutiques ou médicaux tels définis par
                    l’Article 4921-3 du Code de la Santé Publique, dispositions réglementaires (Décret n°2004-802 du
                    29
                    juillet 2004- JO 8 août 2004).
                    Les massages n’ont aucun caractère sexuel.
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h5>Tarifs</h5>
                    <ul>
                      <li>Les prestations sont facturées au prix annoncé lors de la prise de rendez-vous</li>
                      <li>Les tarifs sont affichés en euros toutes taxes comprises, la TVA est non applicable, article
                        293B du CGI
                      </li>
                      <li>Une facture est remise sur demande</li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h5>Annulation de rendez-vous</h5>
                    L’annulation est possible jusqu’à 48H avant le Rendez-vous, les prix de la consultation est dû si le
                    délai n’est pas respecté.
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h5>Modalités de paiement</h5>
                    Le règlement des prestations s’effectue par chèque, espèces.
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="2" id="data-policy">
              <Container>
                <Row>
                  <Col md="12">
                    <h5>Hébergeur :</h5>
                    Dynamix host
                    338 route de la Sainte Baume 13390 Auriol
                    04 84 25 00 04
                    Adhésion à la CNPM MEDIATION CONSOMMATION
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h5>Droit de propriété intellectuelle :</h5>

                    Le domaine https://energy-alix.fr est protégé par les droits de propriété intellectuelle et est la propriété exclusive d’Alix Imhaus. La structure générale, ainsi que son contenu comme les photos, images, textes. En
                    conséquence, toute reproduction, collecte et utilisation des fins personnelles ou commerciales de celle-ci, totales ou partielles, ainsi que toute imitation, sans l’accord exprès, préalable et écrit de Mme Imhaus Alix
                    sont interdites.
                    <br/><br/>
                    Cette interdiction s’étend notamment à tout élément rédactionnel figurant sur le site, logos, photos, images : cette liste n’étant pas limitative. Articles 335-2 et L713-2 du <b>Code de la propriété intellectuelle</b>.
                    <br/><br/>
                    Accès au site
                    <br/><br/>
                    L’accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques,
                    publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités.
                    <br/><br/>
                    Contenu du site
                    <br/><br/>
                    Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner
                    ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
                    Ils sont la propriété pleine et entière de l’éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les
                    applications informatiques, sans l’accord préalable et écrit de l’éditeur, sont strictement interdites. Le fait pour l’éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non
                    autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.
                    <br/><br/>
                    Gestion du site
                    <br/><br/>
                    Pour la bonne gestion du site, l’éditeur pourra à tout moment :
                    <br/><br/>
                    suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internautes ;supprimer toute information pouvant en perturber le
                    fonctionnement ou entrant en contravention avec les lois nationales ou internationales ;suspendre le site afin de procéder à des mises à jour.
                    <br/><br/>
                    Liens hypertextes:
                    <ul>
                      <li>La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l’éditeur. Tout lien devra être retiré sur simple demande de l’éditeur.</li>
                      <li>Toute information accessible via un lien vers d’autres sites n’est pas publiée par l’éditeur. L’éditeur ne dispose d’aucun droit sur le contenu présent dans ledit lien.</li>
                      <li>Photographies et représentation des produit : <br/> Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n’engagent pas l’éditeur.</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <MainFooter/>
    </>
  );
}

export default LegalPage;
