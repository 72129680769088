export const giftCardList = [
  {
    subtitle: "45 Minutes",
    price: "62 €",
    image: require("assets/img/giftCards/cardBackground.jpg"),
    description: "Valable sur l'ensemble des prestations",
    extra: "La durée de 45 min permet de masser une seule zone du corps puis d’effectuer un soin énergétique."
  },
  {
    subtitle: "90 Minutes",
    price: "120 €",
    image: require("assets/img/giftCards/cardBackground.jpg"),
    description: "Valable sur l'ensemble des prestations"
  },
  {
    subtitle: "120 Minutes",
    price: "160 €",
    image: require("assets/img/giftCards/cardBackground.jpg"),
    description: "Valable sur l'ensemble des prestations"
  }
]
