function ProductDescriptionComponent({product}) {
  return (
    <>
      {product && product.detailedDescription && product.detailedDescription.customContent}
      {product && product.detailedDescription && product.detailedDescription.sessionContent && (<h6>Déroulement de la séance :</h6>)}
      {product && product.detailedDescription && product.detailedDescription.sessionContent}
      {product && product.detailedDescription && product.detailedDescription.bienfaits && <hr/>}
      {product && product.detailedDescription && product.detailedDescription.bienfaits && (<h6>Les bienfaits</h6>)}
      {product && product.detailedDescription && product.detailedDescription.bienfaits}
      {product && product.detailedDescription && product.detailedDescription.counterIndications && <hr/>}
      {product && product.detailedDescription && product.detailedDescription.counterIndications && (<h6>Contre-indications</h6>)}
      {product && product.detailedDescription && product.detailedDescription.counterIndications}
    </>
  );
}

export default ProductDescriptionComponent;
