export const hypnoseProductList = [
    {
        id: "regressive_hypnosis",
        tags: ["hypnosis"],
        title: "Hypnose régressive spirituelle",
        subtitle: "concentration",
        image: require("assets/img/products/hypnose/spirituelle.jpg"),
        description: "Technique thérapeutique qui utilise des états de transe guidée pour faciliter l'exploration approfondie des croyances spirituelles, des expériences ou des liens avec une conscience supérieure.",
        duration: "2 Heures / 4 Heures",
        detailedDescription: {
            sessionContent:
                <>
                    <p>Tout d’abord, je vous pose une série de questions pour aborder la séance en pleine
                        conscience.</p>
                    <p>Vous êtes confortablement installés avec coussin, plaid. </p>
                    <p>Une partie d’échange suivit d’un voyage pour oeuvrer à votre bien-être.</p>
                    <p>Séance en présentiel ou en visio.</p>
                    <hr/>
                    <p>C’est une approche pour :</p>
                    <ul>
                        <li>Surmonter les traumatismes de votre passé</li>
                        <li>Retrouver la mémoire (amnésie, oubli d’une période…)</li>
                        <li>Découvrir l’origine de vos problèmes de santé</li>
                        <li>Libération des schémas relationnels, des blocages, vos phobies, vos troubles</li>
                        <li>Trouver des solutions, des réponses de vos questionnements</li>
                        <li>Libération des liens d’attachement (parasites, défunts, croyances limitantes, formes de
                            pensée, émotions..)
                        </li>
                        <li>Trouver un sens et but de votre vie, expériences inexpliqué</li>
                        <li>Transmutation, séance énergétique</li>
                        <li>Trouver des ressources : confiance, force, paix, sérénité….</li>
                    </ul>
                    <br/>
                    <p>On va chercher les clés, du sens et des messages/ conseils et agir là où on ne va pas en général
                        avec les autres techniques :</p>
                    <ul>
                        <li>Niveau karmique</li>
                        <li>Niveau énergétique/symbolique/émotionnel</li>
                        <li>Niveau transgénérationnel</li>
                        <li>Niveau incarnationnel</li>
                        <li>Niveau des âmes</li>
                        <li>Niveau de la conscience supérieure</li>
                        <li>Niveau des Etres de lumière et de vos défunts</li>
                        <li>Niveau de nos origines</li>
                    </ul>
                    <p>L’hypnose spirituelle est une hypnose qui ne sait rien, la plus ouverte possible, adaptée à
                        l’infini de la conscience, de la vie, de l’univers, qui laisse ce qui doit de passer se passer,
                        et qui propose mais n’oblige
                        jamais.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Redonner de l’énergie</li>
                        <li>Identifier l’origine des blessures psychologiques (abandon, humiliation, trahison,
                            injustice, rejet)
                        </li>
                        <li>Libérer les angoisses, les colères, les phobies, les peurs</li>
                        <li>Comprendre les influences des évènements passés dans l’expérience actuelle</li>
                        <li>Porter un autre regard sur la vie et la mort, avancer sereinement sur votre chemin</li>
                        <li>Connexion avec votre Moi Profond</li>
                        <li>Connexion et trouver sa mission de vie</li>
                        <li>Mieux vous connaitre</li>
                        <li>Porter un autre regard sur la vie et la mort</li>
                        <li>Confiance en soi</li>
                        <li>Tout transmuter en lumière (remettre en mouvement, reconsidérer la vie..) envoyer tout en
                            lumière
                        </li>
                        <li>Evolution spirituelle</li>
                    </ul>
                    <p>Se sentir bien avec soi-même pour avancer dans la vie</p>
                </>
        }
    },
    {
        id: "hypnose_corporelle",
        tags: ["hypnosis"],
        titleText: "Hypnose corporelle (Bodhypnosis®)",
        title: <span>Hypnose corporelle <p>(Bodhypnosis®)</p></span>,
        subtitle: "Sérénité",
        image: require("assets/img/products/hypnose/corporelle.jpg"),
        description: "Approche thérapeutique qui se concentre sur la connexion entre l'esprit et le corps, en utilisant des techniques d'hypnose pour favoriser la relaxation, la guérison, et la prise de conscience des sensations physiques.",
        duration: "60 min / 90 min",
        descriptionEnterprise: "Technique habillée sur table de massage ou sur chaise ergonomique. Un relâchement, une détente profonde grâce à des compressions et des décompressions hyper-lentes sur les parties osseuses qui neutralisent le mental.",
        durationEnterprise: "15 min / 20 min / 30 min",
        detailedDescription: {
            sessionContent:
                <>
                    <p>Vous êtes allongé confortablement habillé sur la table de massage, cette technique est à base de
                        compressions d’une quinzaine de secondes et de décompressions d’une trentaine de secondes sur
                        les parties osseuses du corps telle
                        que la tête, la colonne vertébrale, les épaules, les genoux, les pieds.</p>
                    <br/>
                    <p>Sur l’ensemble du corps 10-15 secondes compressions, frictions, vibrations ou percussions, puis
                        la décompression hyper lente 30 secondes.</p>
                    <hr/>
                    <h6>Bodhypnosis®</h6>
                    <p>
                        Cette technique permet de lâcher le mental, de se reconnecter à son âme, de vivre une expérience
                        d’état méditatif ou d’unité avec une perte de notion d’espace et de temps. Étant dans un état
                        parasympathique, elle permet
                        également de libérer le pouvoir d’auto-guérison du corps.
                    </p>
                    <br/>
                    <p>
                        C’est la décompression en hyper lente qui amène à lâcher le mental et à un état de conscience
                        modifié proche de l’hypnose traditionnelle par induction mentale.
                    </p>
                    <hr/>
                    <h6>La technique Bodhypnosis®</h6>
                    <p>
                        <b>Bodhypnosis®</b> est une technique corporelle d’hypnose, elle passe par le corps plutôt que
                        par le mental comme dans l’hypnose traditionnelle.
                    </p>
                    <p>
                        Le mental est neutralisé par la sensation que procure les diverses manipulations que sont les
                        compressions et décompressions, les vibrations, les frictions et rotations avec rythmique
                        particulière.
                    </p>
                    <p>
                        C’est une technique qui ne requiert aucun effort de la part du bénéficiaire et dont les effets
                        sont quasiment immédiats.
                    </p>
                    <br/>
                    <p>
                        <b>Bodhypnosis®</b> amène le bénéficiaire dans l’univers de la sensation.
                    </p>
                    <p>
                        On quitte le monde cartésien de la raison et de l’analyse avec bonheur.
                    </p>
                    <p>
                        La décompression hyper lente ou le ralentissement parfaitement progressif, intriguent le cerveau
                        qui plonge alors au coeur de la sensation, au point de devenir sensation.
                    </p>
                    <p>
                        On entre alors dans le domaine de l’existence pure.
                    </p>
                    <p>
                        Le mental associé à l’ Ego, est complètement dissout, libérant momentanément la personne de la
                        dualité de l’existence et générant ce sentiment de béatitude et de paix intérieure totale.
                    </p>
                    <p>
                        L’expérience d’unité.
                    </p>
                    <p>
                        Vous perdez la notion du corps, de l’espace et du temps, comme dans l’état méditatif profond.
                    </p>
                    <p>
                        Comme si vous étiez <b>UN</b> avec l’univers.
                    </p>
                    <p>
                        C’est un joli voyage vers l’extase qui n’était accessible qu’aux initiés aux grands méditants,
                        avec Bodhypnosis® cela devient accessible.
                    </p>
                    <hr/>
                    <h6>A QUOI SERT LA TECHNIQUE BODHYPNOSIS® ?</h6>
                    <ul>
                        <li>A se retrouver</li>
                        <li>A se détendre & lâcher prise</li>
                        <li>A revenir vers soi</li>
                        <li>A reconnecter avec son âme</li>
                        <li>A se régénérer</li>
                        <li>A remettre le compteur du stress à zéro et se détendre.</li>
                        <li>A libérer le corps de son pouvoir d’auto guérison</li>
                    </ul>
                    <p>
                        ( L’état atteint est l’état parasympathique ou tous les processus d’auto régulation et
                        d’auto-guérison se mettent en branle, comme lors des deux premières heures de la nuit, qui
                        correspond au sommeil profond ou l’activité
                        électrique dans le cerveau est minimale et les ondes delta sont prédominantes. )
                    </p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>C’est régénérateur</li>
                        <li>C’est sédatif</li>
                        <li>C’est soporifique</li>
                        <li>C’est antalgique et même anesthésiant</li>
                    </ul>

                    <p>Tout comme l’hypnose traditionnelle, des protocoles d’affirmations de reprogrammer l’inconscient
                        fonctionneraient de manière très efficace.</p>
                    <p>Un super outil afin de mettre fin à l’auto sabotage, et changer des comportements et attitudes
                        qui ne servent pas notre intérêt.</p>
                    <p>La connexion avec notre Âme permet de mener une vie qui soit plus en accord avec nous mêmes.</p>
                    <p>Plutôt que ce soit le mental et l’égo qui régissent notre existence, si l’âme prend les
                        commandes, si l’âme-agit, c’est la <b>MAGIE DE LA VIE</b> qui survient Alors …!!!</p>
                </>,
            counterIndications:
                <>
                    <p>
                        Cette technique convient à tous. Elle calme et facilite l’endormissement des enfants, les rends
                        plus calmes.
                        L’atteinte rapide du sommeil profond favorise des nuits plus réparatrices pour les enfants et
                        les parents.
                    </p>
                    <p>
                        Pour les personnes malades, cela les apaises et diminue leurs angoisse et soulage leurs douleurs
                        en appliquant un protocole simple adapté à leur condition. Les manipulations impliquant les
                        mains, les pieds et la tête seront
                        privilégiées
                    </p>
                    <hr></hr>
                    <p className="text-center p-1">
                        L’HYPNOSE CORPORELLE BODHYPNOSIS® UN RETOUR VERS SOI<br/>
                        Toucher l’Ame à travers le Corps
                        En neutralisant le mental à travers la sensation, la méthode
                        Bodhypnosis® induit un état méditatif profond.
                        Comme une douce extase.
                        Un retour à soi-même.
                        Une rencontre avec son âme.
                        » JEAN LABBÉ «
                        Créateur de cette merveilleuse technique
                    </p>
                </>,
        }
    }
]
