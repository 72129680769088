import {Col, Container, Row} from "reactstrap";
import CardTestimonial from "../../components/CardTestimonial";
import React from "react";

function TestimonialsSection({testimonials, dark}) {
  return (
    <div className={"section landing-section" + (dark && " section-dark")}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto mb-5" md="8">
            <h2 className="title text-center">Ils m'ont fait confiance</h2>
            <p className="card-description text-center">
              Mon approche centrée sur la relation humaine avec mes clients est une des pierres angulaires de mon
              métier. Et ils sont les mieux placés pour en parler...
            </p>
          </Col>
        </Row>
        <Row>
          {testimonials.map((testimonial) => {
            return (<Col md="4"><CardTestimonial testimonial={testimonial}></CardTestimonial></Col>)
          })}
        </Row>
      </Container>
    </div>
  )
    ;
}

export default TestimonialsSection;
