import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Navigate, Routes, useLocation, Outlet} from "react-router-dom";
import {motion} from "framer-motion";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import LandingPage from "views/LandingPage.js";
// others
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import LegalPage from "./views/LegalPage";
import MassagesPage from "./views/Products/MassagesPage";
import HolisticPage from "./views/Products/HolisticPage";
import HypnosePage from "./views/Products/HypnosePage";
import AboutMePage from "./views/AboutMePage";
import GiftCardsPage from "./views/GiftCardsPage";
import FormationsPage from "./views/FormationsPage";
import PricingPage from "./views/PricingPage";
import PackagesPage from "./views/PackagesPage";
import NotFoundPage from "./views/ErrorPages/NotFoundPage";
import CompanyOfferPage from "./views/CompanyOfferPage";
import FutureMomPage from "./views/FutureMomPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Import the functions you need from the SDKs you need


const firebaseConfig = {
  apiKey: "AIzaSyBY0H3Sgm564GETeMSVS0bbGMnElAbcK78",
  authDomain: "energy-alix.firebaseapp.com",
  projectId: "energy-alix",
  storageBucket: "energy-alix.appspot.com",
  messagingSenderId: "263137669421",
  appId: "1:263137669421:web:e6a457e462dc879f06e3a4",
  measurementId: "G-7BWYX0E7SZ"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const PageLayout = ({children}) => children;

const AnimationLayout = () => {
  const {pathname} = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99]}}
      >
        <Outlet/>
      </motion.div>
    </PageLayout>
  );
};

export default function ScrollToTop({children}) {
  const {pathname, hash} = useLocation();
  useEffect(() => {
    if (!hash) {
      const canControlScrollRestoration = 'scrollRestoration' in window.history
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return children;
}

root.render(
  <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route element={<AnimationLayout/>}>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/massages-boulogne-billancourt/" element={<LandingPage/>}/>
        <Route path="/legal" element={<LegalPage/>}/>
        <Route path="/massages" element={<MassagesPage/>}/>
        <Route path="/holistic" element={<HolisticPage/>}/>
        <Route path="/hypnose" element={<HypnosePage/>}/>
        <Route path="/about-me" element={<AboutMePage/>}/>
        <Route path="/gift-cards" element={<GiftCardsPage/>}/>
        <Route path="/companies" element={<CompanyOfferPage/>}/>
        <Route path="/formations" element={<FormationsPage/>}/>
        <Route path="/packages" element={<PackagesPage/>}/>
        <Route path="/pricing" element={<PricingPage/>}/>
        <Route path="/future-mom" element={<FutureMomPage/>}/>
        <Route path="/not-found" element={<NotFoundPage/>}/>
        <Route path="*" element={<Navigate to="/not-found" replace/>}/>
      </Route>
    </Routes>
  </BrowserRouter>
);
